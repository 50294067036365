@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poller+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');

body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

ul {
  padding: 0;
  margin: 0;
}

li {
  cursor: pointer;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
div {
  font-family: "Poppins", sans-serif;
  max-width: 100%;
}

.txt-ti {
  p {
    color: #A60A24;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.3px;
  }

  h2 {
    font-size: 40px;
    margin-top: 4px;
    text-transform: capitalize;
    font-weight: 600;
  }

}

small {
  font-size: 13px !important;
}

a:hover {
  color: #333;
  transition: all 0.4s ease-in-out;
  text-decoration: none;
}

a,
p {
  font-size: 16px;
  margin-bottom: 0;
}

input:focus {
  border-color: rgba(94, 94, 94, 0.1) !important;
}


.ant-input {
  padding: 16px 11px;
}

.ant-picker {
  width: 100%;
  height: 56px;
  box-shadow: none !important;
  border-color: #d9d9d9 !important;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-input-number {
  width: 100%;
  box-shadow: none !important;
  border-color: #d9d9d9 !important;

  .ant-input-number-input {
    padding: 15px 11px;
  }
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-radio-checked .ant-radio-inner {
  border-color: #61b91c !important;
  background-color: #61b91c !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #61b91c !important;
}

.ant-radio-group {
  width: 100%;
}

.react-international-phone-country-selector-dropdown {
  z-index: 2;
}

img {
  max-width: 100%;
}

footer {
  display: block;
}

figure {
  margin: 0 0;
}

.mr-80 {
  margin: 80px 0 !important;
}

.ant-input-outlined:focus-within,
.ant-input-outlined:hover {
  border: none;
  box-shadow: none;
  background: #fff;
}

.ant-input-outlined {
  border-color: transparent;
  border-width: 0;
}

button {
  outline: 0;
  border: none;
}

label {
  font-weight: bold;
  font-size: 15px;
}

.btn-main {
  height: 48px;
  padding: 0 12px;
  width: 167px;
  background: #61b91c;
  justify-content: space-between;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  box-shadow: none;
  border-radius: 10px;

  svg {
    // width: 36px;
    // height: 20px;
    color: #fff;
    margin-left: 17px;
  }

  p {
    font-size: 16px !important;
    letter-spacing: 1.5px;
    font-weight: 500 !important;
    color: #fff !important;
    margin: 0 !important;
  }
}

.btn-main:hover {
  border-color: transparent;
  background: rgba(97, 185, 28, 0.5);
  transition: all 0.6s ease-in-out;
}

.ant-select-single {

  height: 56px;
  font-size: 16px;

  .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }


}

.ant-select-single:hover {}

.ant-select-dropdown .ant-select-item-option-selected {
  background: rgba(166, 10, 36, 0.2) !important;
}

.relative {
  position: relative;
}

.swiper {
  .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
  }

  .swiper-pagination-bullet-active {
    background: #f59e0c;
  }
}

.tab-trans {
  width: 259px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  cursor: pointer;

  svg {
    margin-left: 10px;

    path {
      fill: #61b91c;
    }
  }

  p {
    font-weight: 500;
    font-size: 15px;
    margin: 13px 10px 13px 0;
  }
}

.bg-cv {
  width: 100%;
  content: "";
  height: 100%;
  position: absolute;
  z-index: 1;
  background-image: -webkit-linear-gradient(top, rgba(97, 185, 28, 0.768627451), rgba(97, 185, 28, 0.9176470588), #61b91c, #61b91c, #61b91c, #61b91c, #61b91c);
}

.ant-select-single .ant-select-selector  {
  font-size: inherit;
}

.swiper-pagination-vertical {
  width: 10px !important;
}

.ck {
  height: 250px;
}

.btn-submit-j {
  width: "100%";
  background: #61b91c;
  height: auto;
  padding: 6px 0;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  width: 170px;
  margin-top: 20px;
  color: #fff;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
}

.btn-submit-j:hover {
  background: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid rgba(97, 185, 28, 0.5);
  color: rgba(97, 185, 28, 0.8) !important;

}

//animate
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes fadeInleftM {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}



// responsive 

@media (max-width: 992px) {

  // contact-page
  .contact-wrap {
    .container {
      .row {
        .col-6 {
          width: 100%;
        }

        .col-6:nth-child(2) {
          margin-top: 50px;
        }
      }
    }
  }

}

@media (max-width: 480px) {

  // contact-page
  .contact-wrap {
    .container {
      .row {
        .col-6 {
          width: 100%;
        }

        .col-6:nth-child(1) {
          .boxes {
            >div {
              width: 100% !important;

              p:nth-child(3) {
                padding-right: 0;
              }
            }

            >div:nth-child(n+2) {
              margin: 8px 0 !important;
            }
          }
        }
      }
    }
  }

}

@media (min-width: 1440px) {
  .container {
    max-width: 85vw;
  }

  .ant-form-item {
    margin-bottom: 1.1111111111111112vw;
  }

  .ant-select-selector {
    padding: 0 0.7638888888888888vw;
  }

  .ant-select-dropdown .ant-select-item {
    min-height: 2.2222222222222223vw;
    padding: 0.3472222222222222vw 0.8333333333333334vw;

    font-size: 0.9722222222222222vw;
    border-radius: 0.2777777777777778vw;
  }
  .ant-picker .ant-picker-input >input {
    font-size:  0.9722222222222222vw;
  }

  .ck {
    height: 25vw;
  }

  .btn-submit-j {
    width: 11.2vw;
    font-size: 1.1vw;
  }

  .tab-trans {
    width: 18vw;
    padding: 0.2vw 1.1vw;

    svg {
      margin-left: 0.6vw;
      width: 1.6vw !important;
      height: 1.6vw !important;

      path {
        fill: #61b91c;
      }
    }

    p {
      font-weight: 500;
      font-size: 1vw;
      margin: 1.1vw 0.6vw 1.1vw 0;
    }
  }

  .btn-main {
    height: 3.6vw;
    padding: 0 0.7vw;
    width: 10.5vw;
    background: #61b91c;
    border-radius: 0.4vw;

    svg {
      width: 1.8vw;
      height: 2.4vw;
      margin-left: 0.8vw;
    }

    p {
      font-size: 0.9999vw !important;
    }
  }

  .react-international-phone-input, .react-international-phone-country-selector-button {
    height: 3.9vw !important;
    padding: 0 0.7vw !important;
  }
  .react-international-phone-flag-emoji {
    width: 1.5vw !important;
    height: 1.5vw !important;
}
.react-international-phone-country-selector-button__dropdown-arrow {
  border-top: 0.3vw solid #777 !important;
  border-right: 0.3vw solid transparent !important;
  border-left: 0.3vw solid transparent !important;
  margin-right: 0.1vw;
  margin-left: 0.2vw;
}

textarea {
  font-size: 0.9vw;
  padding: 1.1vw 0.9vw;
}
input, textarea {
  font-size: 0.9vw !important;
}

  .ant-drawer {
    .ant-drawer-content-wrapper {
      width: 25vw !important;

      .drawer-drag-right-menu {
        .moveic {
          top: 0.9vw;
          left: 0.8vw;
        }

        .drawer-drag-rm-content {
          padding: 3.6vw 1.7vw;
          >p {
            font-size: 1vw;
            padding-right: 0.7vw;
            margin: 2.3vw 0;
          }

          .drawer-drag-content-logo img {
            width: 10.5vw;
            height: 4.2vw;
          }

          >.infos li {
            p {
              padding-right: 3.5vw;
              font-size: 1vw;
            }

            h6 {
              font-size: 1.1vw;
              margin-bottom: 0.3vw;
            }
          }

          >.ic-circle-infos li {
            width: 3.4vw;
            height: 3.4vw;
            svg {
              width: 1.6vw;
              height: 1.6vw;
            }
          }
        }
      }
    }

  }



}