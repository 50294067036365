.blog-container {
    .container {
        .row:nth-child(1) {
            .col-8 {
                padding-right: 30px;

                .wrap-box-sinblog {
                    padding-bottom: 25px;
                    border-bottom: 1px solid #BDBDBD;

                    >img {
                        height: 500px;
                        border-radius: 20px;
                        width: 100%;
                        cursor: pointer;
                    }

                    >.d-flex {

                        margin-top: 30px;

                        .tag {
                            padding: 7px 12px;
                            background: #61b91c;
                            color: #fff;
                            border-radius: 10px;

                            p {
                                font-weight: 700;
                                font-size: 14px;

                            }
                        }

                        .tag-time {
                            text-align: center;
                            padding: 6px 16px;
                            color: #8B8B8B;
                        }

                    }

                    >h2 {
                        font-weight: 800;
                        font-size: 26px;
                        margin-top: 10px;
                        line-height: 32px;
                        letter-spacing: 1px;
                        cursor: pointer;
                    }

                    .desc-wr {
                        margin-top: 10px;
                        color: #696969;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    >.rm {
                        font-size: 16px;
                        font-weight: 500;
                        color: #61b91c;
                        margin-top: 6px;
                        cursor: pointer;

                    }
                }

                .wrap-box-sinblog:nth-child(n+2) {
                    margin-top: 35px;
                }

                .pagination {
                    margin-top: 25px;

                    >.d-flex {

                        li:nth-child(1),
                        li:nth-child(3) {
                            width: 36px;
                            height: 36px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            cursor: pointer;
                            background: #61b91c;

                            svg {
                                width: 15px;
                                color: #fff;
                                height: 15px;
                                font-weight: 600;
                            }
                        }

                        li:nth-child(2) {
                            margin: 0 12px;
                            font-weight: 500;
                            font-size: 18px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }


            }

            .col-4 {
                padding: 0 30px;

                .ant-input-wrapper {
                    input {
                        border: none;
                        background: #61b91c;
                        color: #fff;
                        padding: 16.5px 15px;
                        border-radius: 10px 0 0 10px;
                    }

                    input:focus {
                        border: none;
                    }

                    input::placeholder {
                        color: #fff;
                    }

                    .ant-btn {
                        height: 55px;
                        background: #61b91c;
                        border-radius: 0 10px 10px 0 !important;
                        width: 40px;

                        .ant-btn-icon {
                            font-size: 18px;
                        }
                    }
                }

                .left-content-o,
                .left-content-t {
                    margin-top: 30px;

                    h6 {
                        font-weight: 700;
                        font-size: 20px;
                        padding-bottom: 13px;
                        border-bottom: 1.5px solid rgba(210, 210, 210, 0.6);
                    }

                    ul {
                        margin-top: 15px;
                    }
                }

                .left-content-o {
                    ul {
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .i-lc-left {
                                span:nth-child(1) {
                                    font-size: 16px;

                                    svg {
                                        margin-bottom: 3px;
                                        color: #61b91c;
                                    }
                                }

                                span:nth-child(2) {
                                    margin-left: 6px;
                                    font-weight: 400;
                                }
                            }

                            .i-lc-right {
                                padding: 5px 12px;
                            }
                        }

                        li:nth-child(n+2) {
                            margin-top: 8px;
                        }
                    }
                }

                .left-content-t {
                    ul {
                        li {
                            margin: 15px 0;

                            .i-lc-left {
                                width: 35%;
                                img {
                                    width: 140px;
                                    height: 102px;
                                    border-radius: 10px;
                                }
                            }

                            .i-lc-right {
                                width: 65%;
                                padding-left: 15px;
                                padding-top: 5px;

                                p:nth-child(1) {
                                    font-weight: 600;
                                    font-size: 17px;
                                    -webkit-line-clamp: 1;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                }

                                p:nth-child(2) {
                                    color: #8B8B8B;
                                    font-size: 15px;
                                    font-weight: 400;
                                }

                                p:nth-child(3) {
                                    font-size: 14px;
                                    color: #61b91c;
                                    font-weight: 400;
                                    margin-top: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.active-blog-cat {
    text-align: center;
    color: #fff;
    background: #61b91c;
    border-radius: 10px;

    span {
        font-size: 16px;
    }

    font-weight: 600;
}

.wrapBlogDel {
    margin: 80px auto;

    .ct-inf {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;

        .in {
            display: flex;

            p {
                font-size: 14px;
                font-weight: 500;
                color: #b5b5b5;
                padding-right: 10px;
                border-right: 1px solid #b5b5b5;
            }

            p:nth-child(2) {
                padding-left: 10px;
            }
        }

        .tabhead {
            padding: 5px 8px;
            width: fit-content;
            border-radius: 5px;
            background: #61b91c;
            color: #fff;
            display: flex;
            justify-content: space-around;
            align-items: center;

            p {
                margin: 0 7px;
                font-size: 14px;
                font-weight: bold;

            }

            svg {
                width: 14px;
                height: 14px;
            }
        }
    }

    img {
        width: 100%;
        margin: 10px auto;
        height: auto !important;
    }

    .desc {
        margin-top: 20px;

        p {
            text-align: justify;
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
            line-height: 21px;

            span {
                font-size: 15px;
            }
        }

        ul {
            margin: 4px 0;
            padding-left: 30px;

            li {
                list-style-type: circle;
                margin-top: 3px;
            }
        }

    }

    h1 {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }

    h2,
    h3,
    h4 {
        font-size: 19px;
        line-height: 24px;
        font-weight: 500;
        margin: 5px 0;
    }
}

@media (max-width: 992px) {
    .blog-container {
        .container {
            .row:nth-child(1) {

                .col-8,
                .col-4 {
                    flex: 0 0 auto;
                    width: 100%;
                }

                .col-4 {
                    order: 1;
                }

                .col-8 {
                    order: 2;
                    padding-right: 5px;
                }
            }
        }
    }
}

@media (min-width:1440px) {
    .wrapBlogDel {
        margin: 7vw auto;

        .ct-inf {
            margin-bottom: 2.1vw;

            .tabhead {
                padding: 0.4vw 0.6vw;

                p {
                    margin: 0 0.5vw;
                    font-size: 0.99999vw;
                    font-weight: bold;

                }

                svg {
                    width: 0.9999vw;
                    height: 0.9999vw;
                }
            }

            .in {
                p {
                    font-size: 0.99999vw;
                }
            }
        }

        h1 {
            font-size: 2.1vw;
            line-height: 2.5vw;
        }

        h2,
        h3,
        h4 {
            font-size: 1.3vw;
            margin: 0.6vw 0;
        }

        .desc {
            margin-top: 2.1vw;

            p {
                font-size: 0.9999vw;
                line-height: 1.3vw;

                span {
                    font-size: 0.9999vw;
                }
            }
        }


    }

    .blog-container {
        .container {
            .row {
                margin-top: 5.5vw !important;
            }

            .row:nth-child(1) {
                .col-4 {
                    .left-content-t {
                        margin-top: 2vw;
                        h6 {
                            font-size: 1.4vw;
                            padding-bottom: 0.9vw;
                            border-bottom: 0.12222vw solid rgba(210, 210, 210, 0.6);
                        }
                        ul {
                            margin-top: 1.1vw;
                            li {
                                margin: 1.1vw 0;
                                .i-lc-left img {
                                    width: 9.7vw;
                                    height: 7.1vw;
                                    border-radius: 0.7vw;
                                }
                                .i-lc-right {
                                    padding-left: 1.01vw;
                                    padding-top: 0.4vw;
                                    p:nth-child(1) {
                                        font-size: 1.2vw;
                                    }
                                    p:nth-child(2) {
                                        font-size: 1vw;
                                    }
                                    p:nth-child(3) {
                                        font-size: 0.9vw;
                                        margin-top: 0.3vw;
                                    }
                                }

                            }
                        }
                    }
                    .ant-input-wrapper {
                        .ant-btn {
                            height: 3.8vw;
                            border-radius:0 0.7vw 0.7vw 0 !important;
                            width: 2.8vw;
                            svg {
                                width: 1.1vw;
                                height: 1.1vw;
                            }
    
                        }
                        input {
                            padding: 1.16vw 0.9vw;
                            height: 3.8vw;
                            border-radius: 0.7vw 0 0 0.7vw;
                            font-size: 1vw;
                        }
    
                    } 
                } 
                .col-8 {
                    padding-right: 2.1vw;
    
                    .wrap-box-sinblog {
                        >img {
                            height: 34.7vw;
                            border-radius: 1.4vw;
                        }
    
                        >.d-flex {
                            .tag {
                                padding: 0.5vw 0.8vw;
                                border-radius: 0.7vw;
    
                                p {
                                    font-size: 0.999999vw;
                                }
                            }
                            .tag-time {
                                padding: 0.4vw 1.1vw;
                                p {
                                    font-size: 1.1vw;
                                }
                            }
                        }
                        > h2 {
                            font-size: 1.8vw;
                            margin-top: 0.7vw;
                            line-height: 2.4vw;
                        }
                        .desc-wr {
                            margin-top: 0.7vw;
                            font-size: 1.1vw;
                            line-height: 1.7vw;
                        }
                        > .rm {
                            font-size: 1.1vw;
                            margin-top: 0.5vw;
                        }
                    }
                }
            }

        }
    }

}

@media (max-width: 768px) {
    .blog-container {
        .container {
            .row:nth-child(1) {
                .col-8 {
                    order: 2;

                    .wrap-box-sinblog {
                        >img {
                            height: 400px;
                        }
                    }
                }
            }
        }
    }

    .wrapBlogDel {
        .ct-inf {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;

            .in {
                flex-direction: column;
                margin-top: 20px;
                align-items: flex-start;

                p:nth-child(2) {
                    padding-left: 0;
                    margin-top: 10px;
                }
            }
        }
    }

}

@media (max-width: 480px) {
    .blog-container {
        .container {
            .row:nth-child(1) {
                .col-8 {
                    order: 2;

                    .wrap-box-sinblog {
                        >img {
                            height: 250px;
                        }
                    }
                }
            }
        }
    }

}