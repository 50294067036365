.wrap-home {
    .sl-1 {
        .swiper {

            .swiper-slide {
                position: relative;

                >img {
                    width: 100%;
                    height: 700px;
                    position: relative;
                    object-fit: cover;
                }

                >div {
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 30%;

                    >div:nth-child(1) {
                        max-width: 30%;

                        h2 {
                            font-weight: bold;
                            font-size: 68px;
                            text-transform: uppercase;
                            color: #fff;
                            letter-spacing: 1.2px;

                            span {
                                color: #61b91c;
                            }
                        }

                        p {
                            font-size: 27px;
                            font-weight: 300;
                            color: #fff;
                        }
                    }

                    >div:nth-child(2) {
                        background: rgba(255, 255, 255, 0.5);
                        max-width: 35%;
                        padding: 45px 35px;
                        border-radius: 20px;

                        p:nth-child(1) {
                            font-weight: bold;
                            font-size: 23px;
                            text-transform: uppercase;
                            color: #111;
                            letter-spacing: 1.2px;
                        }

                        p:nth-child(2) {
                            font-size: 18px;
                            text-align: justify;
                            font-weight: 300;
                            margin-top: 15px;
                            color: #353535;
                            padding-right: 10px;
                        }
                    }
                }
            }

            .swiper-slide::before {
                width: 100%;
                content: "";
                height: 100%;
                background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0), #000000);
                z-index: 2;
                position: absolute;
            }

            .btn-slide {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                p {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 15px;
                    transform: rotate(90deg);
                    cursor: pointer;

                }

                p:nth-child(2) {
                    margin-right: 15px;
                }
            }
        }
    }

    .sl-2 {
        .row {
            width: 100%;
            margin: 0;
            padding: 0;
            height: 690px;

            .col-4 {
                height: 100%;
            }

            figure,
            img {
                height: 100%;
                width: 100%;
            }

            img {
                object-fit: cover;
                transition: 1s;
                -webkit-transition: 1s;
            }

            .col-4:nth-child(1)::before {
                width: 100%;
                content: "";
                // background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.6117647059), rgba(0, 0, 0, 0.2), #000000);
                background: rgba(0, 0, 0, 0.2);
                z-index: 2;
                position: absolute;
                z-index: 1;

            }

            .col-4:nth-child(3)::before {
                width: 100%;
                content: "";
                background: rgba(0, 0, 0, 0.15555);
                // background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.6117647059), rgba(0, 0, 0, 0.2), #000000);
                z-index: 2;
                position: absolute;
            }

            .col-4:nth-child(1),
            .col-4:nth-child(3) {
                overflow: hidden;

                .ct-l {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    z-index: 2;
                    flex-direction: column;
                    justify-content: space-between;

                    >p {
                        color: #ffffff;
                        text-transform: uppercase;
                        font-weight: 500;
                        margin: 20px 0 0 20px;
                        font-size: 18px;
                        font-family: "Nunito", sans-serif;
                    }

                    .ct-l-bot {
                        text-align: center;
                        padding-bottom: 90px;

                        h3 {
                            font-family: "Permanent Marker", sans-serif;
                            font-size: 62px;
                            color: #fff;

                        }
                    }
                }
            }

            .col-4:nth-child(3) {
                .ct-l {
                    >p {
                        text-align: right;
                        margin: 0 20px 20px 0;

                    }

                    .ct-l-bot {
                        padding-top: 90px;

                        h3 {
                            color: #f59e0c;
                            text-shadow: 0 1.36px 10.896px #fff, 0 -5px 1px #fff;
                        }
                    }
                }
            }

            .col-4:nth-child(1) {
                .ct-l {
                    .ct-l-bot {
                        h3 {
                            color: #61b91c;
                            text-shadow: 0 1.36px 10.896px #fff, 0 -5px 1px #fff;
                        }
                    }
                }


            }

            .col-4:nth-child(2) {
                position: relative;
                padding: 0;

                img {
                    position: relative;
                }

                .ct {
                    position: absolute;
                    width: 100%;
                    z-index: 2;
                    padding: 0 40px;
                    margin-top: 70px;

                    h1 {
                        font-weight: 700;
                        font-size: 55px;
                        color: #fff;
                    }

                    p {
                        font-size: 15px;
                        line-height: 24px;
                        color: #fff;
                        font-weight: 300;
                        margin-top: 30px;
                        line-height: 25px;
                    }

                    .btn-main {
                        background: #111;
                        margin-top: 20px;
                        width: 140px;
                    }

                    button:hover {
                        background: rgba(255, 255, 255, 0.3);
                        transition: all 0.85s;
                        -webkit-transition: all 0.85s;
                    }
                }

            }


            .col-4:nth-child(1):hover,
            .col-4:nth-child(3):hover {
                figure {
                    img {
                        transform: scale(1.1);
                    }
                }

            }

            .col-4:nth-child(1),
            .col-4:nth-child(3) {
                padding: 0;
                position: relative;
                width: 32%;
            }

            .col-4:nth-child(2) {
                width: 36%;
            }
        }
    }

    .sl-3 {
        position: relative;
        height: 650px;

        >img {
            position: relative;
            height: 100%;
            object-fit: cover;
            width: 100vw;
        }

        >.row {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            padding: 0;
            margin: 0;
            top: 0;
            left: 0;

            .col-4 {
                padding: 0;

            }

            .col-4:nth-child(1),
            .col-4:nth-child(3) {
                width: 32%;
            }

            .col-4:nth-child(2) {
                width: 36%;

                .bg-conti {
                    height: 85%;
                    position: relative;
                    width: 100%;
                    border-radius: 0 0 300px 300px;
                    background: #61b91c;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >div {
                        width: 88%;
                        position: absolute;
                        top: -29%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

        }

        .content-up {
            display: flex;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 3;

            .ct-box-sli-sl3 {
                width: 75%;
                height: 85%;
                background: #fff;
                border-radius: 120px 0 0 0;

                >.row {
                    padding: 0;
                    margin: 0;
                    height: 100%;

                    .col {
                        padding: 0;
                        height: 100%;
                    }

                    .col:nth-child(2) {

                        width: 60%;

                        .swiper {
                            height: 100%;

                            img {
                                object-fit: cover;
                                height: 100%;
                                width: 100%;
                            }
                        }

                    }

                    .col:nth-child(1) {

                        width: 40%;
                        padding-top: 70px;
                        padding-left: 70px;
                        padding-right: 20px;

                        .ct-up {
                            .desct1 {
                                font-size: 15px;
                                max-width: 82%;
                                font-weight: 400;
                                margin-top: 12px;
                                overflow: hidden;
                                -webkit-line-clamp: 4;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                            }

                            .desh1 {
                                font-size: 27px;
                                font-weight: 700;


                            }
                        }

                        .active {
                            .desh1 {
                                -webkit-animation: fadeInleftM 0.5s running ease-out 1s both;
                                animation: fadeInleftM 0.5s running ease-out 1s both;
                            }

                            .desct1 {
                                -webkit-animation: fadeInleftM 1.2s running ease-out 1s both;
                                animation: fadeInleftM 1.2s running ease-out 1s both;
                            }
                        }


                        .move-c-na {
                            margin-top: 50px;

                        }

                        .circle-des-wrap {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-top: 40px;

                            .circle-des {
                                width: 130px;
                                border-radius: 50%;
                                height: 130px;
                                padding: 5px;
                                position: relative;
                                border-radius: 4px solid #fff;

                                img {
                                    height: 100%;
                                    position: relative;
                                    border-radius: 50%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }

                            .circle-des-ct {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                top: 0;
                                left: 0;
                                background: rgba(97, 185, 28, 0.7);

                                p {
                                    font-size: 17px;
                                    color: #fff;
                                    font-weight: 500;
                                }
                            }

                        }

                        .active-cir {
                            .circle-des {
                                .circle-des-ct {
                                    p {
                                        -webkit-animation: zoomIn 1.5s ease-out 1s both;
                                        animation: zoomIn 1.5s ease-out 1s both;
                                    }
                                }

                            }
                        }




                    }
                }
            }

            .txt-big-des {
                width: 25%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 85%;

                h2 {
                    color: rgba(255, 255, 255, 0.2);
                    font-size: 72px;
                    font-weight: bold;

                }
            }
        }

    }

    .sl-3::before {
        width: 100%;
        content: "";
        position: absolute;
        z-index: 1;
        min-height: 100%;
        background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5568627451), rgba(0, 0, 0, 0.7098039216), rgba(0, 0, 0, 0.737254902), rgba(0, 0, 0, 0.6705882353), rgba(0, 0, 0, 0.8196078431));
    }

    .sl-5 {
        .sl-5-1 {
            height: 720px;

            .col-6 {

                .row {
                    height: 100%;

                    .col-6:nth-child(1) {
                        position: relative;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                            position: relative;
                            object-fit: cover;
                            transition: all 0.85s;
                            -webkit-transition: all 0.85s;
                        }

                        .ct-tour3-1 {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            height: 100%;
                            z-index: 2;

                            h3 {
                                font-family: "Permanent Marker", sans-serif;
                                font-size: 45px;
                                color: #61b91c;
                                text-shadow: 0 1.36px 8.896px #fff, 0 -5px 1px #fff;
                            }

                            .color-ori {
                                color: #f59e0c;
                            }
                        }

                        .box-sale {
                            position: absolute;
                            left: 15px;
                            top: 17px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            z-index: 2;

                            img {
                                width: auto;
                                height: 51px;
                                transform: scale(1.1);
                            }

                            p {
                                span:nth-child(1) {

                                    font-weight: 700;
                                }

                                font-size: 14px;
                                padding-top: 4px;
                                font-weight: 400;
                                margin-left: 16px;
                                color: #fff;
                            }
                        }

                        >p,
                        .tour-txt-link {
                            position: absolute;
                            font-weight: 400;
                        }

                        .tour-txt-corner {
                            bottom: 17px;
                            left: 17px;
                            color: #fff;
                            font-size: 14px;
                            z-index: 2;
                        }

                        .tour-txt-link {
                            bottom: 17px;
                            right: 17px;
                            width: 80px;
                            border: 2px solid #fff;
                            border-radius: 50%;
                            height: 80px;
                            text-align: center;

                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 3;
                            text-decoration: none;
                            cursor: pointer;

                            a {
                                font-size: 14px;
                                text-transform: uppercase;
                                text-decoration: none;
                                color: #fff;
                            }

                            transition: all 0.8s;
                            -webkit-transition: all 0.8s;

                        }

                        .tour-txt-link:hover {
                            color: #383838;
                        }


                    }

                    .col-6:nth-child(1)::before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        content: "";
                        z-index: 1;
                        background: rgba(0, 0, 0, 0.3)
                    }

                    .col-6:nth-child(1):hover {
                        cursor: pointer;

                        img {
                            transform: scale(1.1);

                        }

                        .orihover {
                            background: #f59e0c;
                        }

                        .grehover {
                            background: #61b91c;
                        }
                    }

                    .col-6:nth-child(2) {
                        position: relative;
                        overflow: hidden;

                        img {
                            height: 100%;
                            position: relative;
                            width: 100%;
                        }

                        .bg-cv {
                            top: 0;

                        }

                        >h4 {
                            position: absolute;
                            font-size: 33vw;
                            color: rgba(255, 255, 255, 0.09);
                            z-index: 2;
                            right: -25%;
                            top: 0;
                        }

                        .color-ori-img {
                            background-image: -webkit-linear-gradient(top, rgba(245, 158, 12, 0.768627451), rgba(245, 158, 12, 0.9176470588), #f59e0c, #f59e0c, #f59e0c, #f59e0c, #f59e0c);

                        }

                        .ct {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            z-index: 3;
                            top: 0;
                            padding: 30px;
                            left: 0;
                            color: #fff;

                            >div {
                                >p {
                                    font-size: 14px;
                                    overflow: hidden;
                                    font-weight: 300;
                                    -webkit-line-clamp: 3;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;

                                }

                                >h3 {
                                    font-size: 32px;
                                    font-weight: 700;
                                    text-transform: uppercase;
                                    // color: #111;
                                }

                                ul {
                                    margin-top: 20px;

                                }

                                .tour-in-ex {
                                    li {

                                        p:nth-child(1) {
                                            font-size: 17px;
                                            font-weight: 500;
                                            color: #000;
                                            text-transform: uppercase;
                                            font-family: "Rubik", sans-serif;
                                        }

                                        p:nth-child(n+2) {
                                            overflow: hidden;
                                            font-weight: 300;
                                            font-size: 14px;
                                            -webkit-line-clamp: 4;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-box-orient: vertical;
                                        }
                                    }

                                    li:nth-child(2) {
                                        margin-top: 8px;
                                    }

                                }

                                .service {
                                    display: flex;

                                    li {
                                        svg {
                                            width: 16px;
                                            height: 16px;
                                        }

                                        p {
                                            font-size: 15px;
                                            font-weight: 500;
                                            margin-top: 5px;
                                        }
                                    }

                                    li:nth-child(n+2) {
                                        margin-left: 24px;

                                    }

                                }

                                .packages {
                                    li {
                                        p:nth-child(1) {
                                            font-size: 19px;
                                            font-weight: 500;
                                        }

                                        p:nth-child(2) {
                                            font-size: 30px;
                                            color: #000;
                                            font-weight: 900;
                                            font-family: "Rubik", sans-serif;

                                        }
                                    }

                                    li:nth-child(n+2) {
                                        margin-top: 1vw;

                                    }
                                }
                            }



                            .p-btn {
                                display: flex;
                                justify-content: end;

                                .btn-main {
                                    background: #111;
                                    width: 162px;
                                    margin-top: 25px;
                                    transition: all 0.8s;
                                    -webkit-transition: all 0.8s;
                                }

                                .btn-main:hover {
                                    background: rgba(255, 255, 255, 0.8);

                                    p,
                                    svg {
                                        color: #111 !important;
                                    }


                                }
                            }

                        }
                    }
                }
            }


        }

        .row {
            margin: 0;
            padding: 0;

            .col-6 {
                padding: 0;
            }
        }
    }

    .sl-6 {
        .container {
            margin: 6vw auto;

            .faqs {
                width: 100%;
                max-width: 90%;
                margin: 0 auto;
                margin-top: 50px;

                .faq {
                    margin: 15px;
                    padding: 15px;
                    background-color: #FFF;
                    border-radius: 8px;

                    .tab-ques {
                        display: flex;
                        align-items: start;
                        justify-content: space-between;

                        svg {
                            transition: all 0.8s ease-out;
                            width: 27px;
                            transform: rotate(0deg);

                            height: 27px;
                        }

                        .faq-question {
                            position: relative;
                            padding-right: 80px;
                            font-size: 17px;
                            font-weight: 600;
                            transition: all 0.8s ease;
                        }
                    }

                    .faq-answer {
                        overflow-y: hidden;
                        display: none;
                        transition: all 0.4s ease-out;
                        font-weight: 300;
                        font-size: 15px;
                    }
                }

                .open {
                    background: #61b91c;

                    .tab-ques {
                        .faq-question {
                            margin-bottom: 15px;
                            color: #fff;


                        }

                        svg {
                            transform: rotate(180deg);
                            color: #fff;
                        }
                    }

                    .faq-answer {
                        // max-height: 500px;
                        display: block;
                        opacity: 1;
                        color: #fff;

                    }
                }

                .ev {
                    background: #61b91c;
                }

                .ol {
                    background: #f59e0c;
                }
            }
        }
    }

    .sl-7 {
        background: #f3f3f3;
        padding: 80px 0;

        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            >h3 {
                margin-bottom: 50px;
            }

            .row {

                // max-width: 70.3vw;
                img {
                    transition: all 0.85s;
                    -webkit-transition: all 0.85s;
                    width: 100%;
                    object-fit: cover;
                }

                .col-6 {
                    padding: 0 20px;

                    .info-icons {
                        display: flex;
                        margin-top: 12px;

                        .time,
                        .tag {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            svg {
                                width: 14px;
                                height: 14px;
                                color: #909090;

                            }

                            p {
                                font-size: 12px;
                                padding: 0 4px;
                                color: #909090;
                                font-weight: 500;

                            }
                        }

                        .time {
                            margin-left: 10px;
                        }
                    }

                    >a {
                        figure {
                            border-radius: 10px;
                            overflow: hidden;

                            >img {
                                // height: 20vw;
                                height: 288px;
                            }
                        }
                    }

                    .tbblog1 {

                        font-weight: 700;
                        font-size: 23px;
                        margin-top: 23px;
                        margin-bottom: 6px;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        color: #000;
                        text-decoration: none;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        transition: all 0.85s;
                        -webkit-transition: all 0.85s;
                    }

                    .scbblog1 {

                        font-weight: 300;
                        font-size: 15px;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        transition: all 0.85s;
                        -webkit-transition: all 0.85s;
                    }






                    >.row {
                        >.col-6 {
                            display: flex;
                            flex-direction: column;




                            a {
                                figure {
                                    overflow: hidden;
                                    border-radius: 10px;

                                    img {
                                        // height: 12vw;
                                        height: 172.8px;
                                    }
                                }
                            }

                            .info {
                                >a {
                                    font-size: 18px;
                                    text-decoration: none;
                                    color: #111;
                                    font-weight: 600;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    transition: all 0.85s;
                                    -webkit-transition: all 0.85s;

                                }

                                >a:hover {
                                    color: #61b91c;
                                }


                            }


                        }

                        >.col-6:nth-child(n + 3) {
                            margin-top: 15px;
                        }

                    }

                    a:hover {
                        figure {
                            img {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }

    .sl-8 {
        padding-bottom: 80px;

        .container {
            max-width: 100%;

            >.wrap-libraries {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .l-2 {
                    .row {
                        margin: 0;
                    }
                }
            }
        }
    }

    .sl-4 {
        .row {
            padding: 0;
            margin: 0;

            .col-6 {
                padding: 0;
            }

            .col-6:nth-child(1) {
                .row {
                    .col-6 {
                        height: 250px;
                        cursor: pointer;
                        overflow: hidden;
                        position: relative;

                        a {
                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                transition: all 0.85s;
                                -webkit-transition: all 0.85s;
                            }

                        }

                        .ab-pl {
                            position: absolute;
                            z-index: 2;
                            display: flex;
                            justify-content: end;
                            align-items: end;
                            bottom: 20px;
                            right: 20px;

                            >p {
                                color: #fff;
                                font-weight: 600;
                                font-size: 25px;
                            }

                            .ab-pl-ic {
                                border: 3px solid #fff;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 50px;
                                height: 50px;

                                svg {
                                    color: #fff;
                                    width: 19px;
                                    height: 19px;
                                    margin-left: 3px;
                                }
                            }

                            .ab-pl-ic:hover {
                                background: #61b91c;
                                transition: all 0.7s ease-in-out;
                            }
                        }
                    }

                    .col-6:hover {
                        a {
                            img {
                                transform: scale(1.1);
                            }
                        }

                        .ab-pl {
                            .ab-pl-ic {
                                background: #61b91c;
                                transition: all 0.7s ease-in-out;
                            }
                        }

                    }

                    .col-6:nth-child(4) {
                        background: #202020;
                        position: relative;

                        img {
                            height: 100%;
                            position: absolute;
                            z-index: 1;
                            right: -120px;
                        }

                        .txt-op {
                            position: relative;
                            height: 100%;
                            padding: 75px 0 0 30px;

                            h4 {
                                font-family: "Permanent Marker", sans-serif;
                                color: #fff;
                                font-size: 40px;
                            }
                        }

                        >h5 {
                            position: absolute;
                            font-weight: bold;
                            font-size: 35rem;
                            color: rgba(255, 255, 255, 0.2);
                            z-index: 2;
                            top: 0;
                        }
                    }
                }
            }

            .col-6:nth-child(2) {
                height: 500px;
                position: relative;

                .swiper {
                    height: 100%;

                    .swiper-wrapper {
                        .swiper-slide {
                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                }

                .move-c-na {
                    position: absolute;
                    bottom: 25px;
                    z-index: 2;
                    left: 25px;

                }
            }
        }
    }

    .sl-9 {
        .row {
            padding: 0;
            margin: 0;
            height: 800px;

            .col-6 {
                padding: 0;
                height: 100%;

            }

            img {
                height: 100%;
                width: 100%;
            }

            .col-6:nth-child(1) {
                position: relative;

                figure {
                    height: 100%;
                    position: relative;
                }

                h3 {
                    position: absolute;
                    bottom: 30px;
                    font-family: "Titan One", sans-serif;
                    color: #fff;
                    font-size: 92px;

                    left: 30px;
                    z-index: 1;
                }

                .tab-head-sm {
                    position: absolute;
                    top: 40px;
                    left: 40px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 250px;
                    }

                    p {
                        position: absolute;
                        font-family: "Permanent Marker", sans-serif;
                        font-size: 20px;
                    }
                }

                .socials-contact {
                    z-index: 1;
                    right: 0;
                    top: 0;
                    position: absolute;
                    width: 50px;
                    height: 60%;
                    background: #fff;
                    border-radius: 0 0 0 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    li:nth-child(2) {
                        writing-mode: vertical-rl;
                        text-transform: uppercase;
                        font-size: 18px;
                        color: #909090;
                        cursor: none;
                    }

                    .line-vertical {
                        width: 1.2px;
                        height: 65px;
                        background: #b4b4b4;
                        cursor: none;
                    }

                    li {
                        padding: 14px 0;
                    }

                    .ics-sc {
                        svg {
                            width: 20px;
                            height: 20px;
                            color: #818181;
                        }
                    }

                }

            }

            .col-6:nth-child(2) {
                padding-left: 40px;
                padding-top: 40px;
                padding-right: 30px;

                h3 {
                    font-size: 32px;
                    font-weight: 400;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                }

                p {
                    margin: 9px 0;
                    font-size: 15px;
                    color: #404040;
                    font-weight: 300;
                }

                ul {
                    padding-left: 25px;

                    li {
                        padding: 5px 0;
                        font-size: 15px;
                        font-weight: 300;
                        color: #424242;

                        span {
                            padding-right: 4px;

                            svg {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }

                .info-short {
                    text-align: center;
                    margin-top: 40px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    >div {
                        height: 1.5px;
                        width: 60%;
                        background: #d1d1d1;
                        border-radius: 10px;
                    }

                    p {
                        font-size: 14px;
                        line-height: 32px;
                    }

                    span {
                        color: #fff;
                        background: #61b91c;
                        border-radius: 5px;
                        padding: 2px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .sl-10 {
        .container {
            max-width: 70.3vw;
            padding-top: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .logotrip {
                svg {
                    width: 200px;
                    height: 60px;
                }

                margin-bottom: 18px;
            }

            .row {
                .col {
                    width: 16.6666667%;
                    padding: 0 6px;
                    height: 130px;
                    cursor: pointer;
                    flex: 0 0 auto;

                    figure {
                        overflow: hidden;
                        height: 100%;
                        width: 100%;
                        border-radius: 10px;
                    }

                    img {
                        height: 100%;
                        border-radius: 10px;
                        width: 100%;
                        object-fit: cover;
                        transition: all 0.85s;
                        -webkit-transition: all 0.85s;
                    }
                }

                .col:hover {
                    img {
                        transform: scale(1.1);
                    }
                }

                .col:last-child {
                    a {
                        background: #111;
                        height: 100%;
                        border-radius: 10px;
                        width: 100%;
                        text-decoration: none;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        padding-left: 16px;

                        img {
                            width: 32px;
                            height: 32px;
                        }

                        p {
                            font-size: 13px;
                            font-weight: 600;
                            margin-top: 8px;
                            color: #fff;

                        }

                    }
                }
            }
        }
    }
}

.header-txt-homep {
    text-align: center;
    font-size: 42px;
    font-weight: 800;
}


.move-c-na {
    display: flex;

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        background: #d9d9d9;
        border-radius: 50%;
        height: 32px;
    }

    li:nth-child(2) {
        margin-left: 10px;
    }

    li:hover {
        background: #f59e0c;
        color: #fff;
        transition: all 0.85s;
        -webkit-transition: all 0.85s;
    }

}


@media (max-width: 1235px) {

    .wrap-home .sl-4 .row .col-6:nth-child(2) {
        .swiper {
            .swiper-wrapper {
                .swiper-slide {
                    >div {
                        height: 100%;
                    }
                }
            }
        }
    }

}

@media (min-width: 1440px) {

    .wrap-home .sl-8 {
        padding-bottom: 5vw;

        .container {
            max-width: 70.3vw;
        }
    }

    .wrap-home .sl-5 .sl-5-1 .col-6 .row .col-6:nth-child(1) {
        .ct-tour3-1 h3 {
            font-size: 3.5vw;
        }

        .tour-txt-link {
            font-size: 1vw;
            bottom: 1.1805555555555556vw;
            right: 1.1805555555555556vw;
            width: 5.555555555555555vw;
            border: 0.1388888888888889vw solid #fff;
            height: 5.555555555555555vw;

            a {
                font-size: 0.9722222222222222vw;
            }
        }

        .box-sale {
            left: 1.0416666666666667vw;
            top: 1.1805555555555556vw;

            img {
                height: 3.5416666666666665vw;
            }

            p {
                font-size: 0.9722222222222222vw;
                padding-top: 0.2777777777777778vw;
                margin-left: 1.1111111111111112vw;
            }
        }

        .tour-txt-corner {
            bottom: 1.1805555555555556vw;
            left: 1.1805555555555556vw;
            font-size: 1vw;
        }
    }

    .wrap-home .sl-1 .swiper .btn-slide p {
        font-size: 1.1vw;
    }

    .wrap-home .sl-1 .swiper .swiper-slide>div>div:nth-child(1) {
        h2 {
            font-size: 4.4vw;
        }

        p {
            font-size: 1.799999vw;
        }
    }

    .wrap-home .sl-1 .swiper .swiper-slide>img {
        height: 54vw;
    }

    .wrap-home .sl-7 .container .row .col-6 .info-icons {

        .time,
        .tag {
            svg {
                width: 1vw;
                height: 1vw;
            }

            p {
                font-size: 0.9vw;
            }

        }


    }

    .header-txt-homep {
        font-size: 2.4vw;
    }

    .wrap-home .sl-6 .container .faqs {
        .faq {
            .tab-ques .faq-question {
                font-size: 1.2vw;
            }

            .faq-answer {
                font-size: 0.999999vw;
            }
        }
    }

    .wrap-home .sl-2 .row .col-4:nth-child(2) .ct .btn-main {
        width: 9.666vw;
    }

    .wrap-home .sl-2 .row .col-4:nth-child(2) .ct h1 {
        font-size: 3.8vw;
    }

    .wrap-home .sl-9 .row {
        height: 54vw;
    }

    .wrap-home .sl-9 .row .col-6:nth-child(1) .tab-head-sm {
        top: 3vw;
        left: 2.8vw;
    }

    .wrap-home .sl-9 .row .col-6:nth-child(1) .socials-contact .line-vertical {
        width: 0.1vw;
        height: 4.2vw;
    }

    .wrap-home .sl-9 .row .col-6:nth-child(1) .socials-contact {
        li:nth-child(2) {
            p {
                font-size: 1.1vw;
            }
        }
    }

    .wrap-home .sl-9 .row .col-6:nth-child(1) .socials-contact {
        width: 3.4vw;
        border-radius: 0 0 0 4.3vw;

        li {
            padding: 1vw 0;
        }

        li:nth-child(2) {
            p {
                font-size: 1.1vw;
            }
        }

        .ics-sc {
            a {
                svg {
                    width: 1.4vw;
                    height: 1.4vw;
                }
            }
        }
    }

    .wrap-home .sl-9 .row .col-6:nth-child(2) {
        padding-left: 2.7vw;
        padding-top: 2.7vw;
        padding-right: 2.3vw;

        h3 {
            font-size: 2.2vw;
            margin-bottom: 1.4vw;
        }

        p {
            margin: 0.8vw 0;
            font-size: 1.055555vw;
        }

        ul {
            li {
                padding: 0.3vw 0;
                font-size: 1.055555vw;

            }
        }

        .info-short {
            margin-top: 2.7vw;

            >div {
                height: 0.1vw;
                border-radius: 0.5vw;
            }

            p {
                font-size: 0.9999999vw;
                line-height: 2.2vw;
            }

        }
    }

    .wrap-home .sl-9 .row .col-6:nth-child(1) h3 {
        bottom: 2.8vw;
        font-size: 6.5vw;
        left: 2.5vw;
    }

    .wrap-home .sl-9 .row .col-6:nth-child(1) .tab-head-sm img {
        width: 17vw;
    }

    .wrap-home .sl-9 .row .col-6:nth-child(1) .tab-head-sm p {
        font-size: 1.4vw;
    }

    .wrap-home .sl-5 .sl-5-1 {
        height: 50vw;
    }

    .wrap-home .sl-7 .container .row .col-6 {
        .tbblog1 {
            font-size: 1.4vw;
        }

        .scbblog1 {
            font-size: 1vw;
        }

        >.row>.col-6 .info>a {
            font-size: 1.042vw;
        }
    }

    .wrap-home .sl-2 .row {
        height: 45vw;

        .col-4:nth-child(1),
        .col-4:nth-child(3) {
            .ct-l .ct-l-bot h3 {
                font-size: 5vw;
            }
        }

    }

    .wrap-home .sl-2 .row .col-4:nth-child(2) .ct p {
        font-size: 1vw;
        line-height: 2vw;
    }

    .wrap-home .sl-3 .content-up .ct-box-sli-sl3>.row .col:nth-child(1) .ct-up {
        .desh1 {
            font-size: 2.2vw;
        }

        .desct1 {
            font-size: 0.899999vw;
        }
    }

    .wrap-home .sl-3 {
        height: 41vw;
    }

    .wrap-home .sl-3 .content-up .ct-box-sli-sl3>.row .col:nth-child(1) {


        .circle-des-wrap .circle-des {
            p {
                font-size: 1.3vw;
            }

            width: 10vw;
            height: 10vw;
        }
    }

    .move-c-na li {
        height: 2vw;
        width: 2vw;
    }

    .wrap-home .sl-5 .sl-5-1 .col-6 .row .col-6:nth-child(2) .ct {
        padding: 2.0833333333333335vw;

        >div {
            h3 {
                font-size: 2.2vw;
            }

            >p {
                font-size: 0.9888888vw;

            }

            ul {
                margin-top: 1.3888888888888888vw;
            }

            .tour-in-ex {
                li {

                    p:nth-child(1) {
                        font-size: 1.1805555555555556vw;
                    }

                    p:nth-child(n+2) {
                        font-size: 0.9722222222222222vw;
                    }
                }

                li:nth-child(2) {
                    margin-top: 0.5555555555555556vw;
                }

            }

            .packages li {
                p:nth-child(1) {
                    font-size: 1.3vw;
                }

                p:nth-child(2) {
                    font-size: 2.5vw;
                }
            }

            .service {
                li {
                    svg {
                        width: 1.6vw;
                        height: 1.6vw;
                    }

                    p {
                        font-size: 0.9vw;
                        margin-top: 0.3472222222222222vw;
                    }
                }

                li:nth-child(n+2) {
                    margin-left: 1.6666666666666667vw;
                }
            }
        }

    }

    .wrap-home .sl-6 .container .faqs {
        max-width: 70.3vw;
    }

    .wrap-home .sl-4 .row {
        .col-6:nth-child(1) {
            .row {
                .col-6 {

                    height: 17.35vw;

                    .ab-pl {
                        bottom: 1.4vw;
                        right: 1.4vw;

                        .ab-pl-ic {
                            border: 0.277777vw solid #fff;
                            width: 4vw;
                            height: 4vw;

                            svg {
                                width: 1.5vw;
                                height: 1.5vw;
                                margin-left: 0.2vw;
                            }
                        }
                    }

                }

                .col-6:nth-child(4) {
                    h5 {
                        font-size: 45vw;
                    }

                    .txt-op {
                        padding: 4.6vw 0 0 2.2vw;

                        h4 {
                            font-size: 3.1vw;
                        }
                    }

                    img {
                        right: -9vw;
                    }
                }
            }
        }

        .col-6:nth-child(2) {
            height: 34.7vw;

            .swiper {
                .swiper-pagination {
                    height: 8vw !important;
                    width: 0.9vw !important;

                    .swiper-pagination-bullet {
                        margin: 0.4vw 0;
                        width: 0.6vw;
                        height: 0.6vw;
                    }
                }
            }

            .move-c-na {
                bottom: 1.7vw;
                left: 1.7vw;

                li {
                    svg {
                        width: 1.3vw;
                        height: 1.3vw;
                    }
                }

                li:nth-child(2) {
                    margin-left: 0.8vw;
                }
            }
        }
    }

    .wrap-home .sl-5 .sl-5-1 .col-6 .row .col-6:nth-child(2) .ct .p-btn .btn-main {
        width: 10.4vw;
        margin-top: 1.25vw;
    }

    .wrap-home .sl-10 .container {
        padding-top: 4.5vw;

        .logotrip {
            svg {
                width: 14vw;
                height: 3.5vw;
            }

            margin-bottom: 1.5vw;
        }

        .row {
            margin: 0;

            .col {
                height: 9.2vw;
                padding: 0 0.6vw;

                figure {
                    border-radius: 0.8vw;
                }
            }

            .col:last-child {
                a {
                    background: #111;
                    border-radius: 0.8vw;
                    padding-left: 1.2vw;

                    img {
                        width: 2.3vw;
                        height: 2.3vw;
                    }

                    p {
                        margin-top: 0.6vw;
                        font-size: 0.9vw;
                    }
                }
            }
        }
    }

    .wrap-home .sl-6 .container .faqs {
        margin-top: 3.7vw;

        .faq {
            margin: 1.2vw;
            padding: 1.2vw;
            border-radius: 0.7vw;

            .tab-ques {
                svg {
                    width: 1.7vw;
                    height: 1.7vw;
                }
            }
        }
    }

    .wrap-home .sl-7 .container .row .col-6>a figure>img {
        height: 23.7vw;
    }

    .wrap-home .sl-7 .container .row .col-6>.row>.col-6 a figure img {
        height: 12vw;
    }

    .wrap-home .sl-7 .container .row .col-6 .info-icons {
        margin-top: 0.8vw;
    }

    .wrap-home .sl-7 .container .row .col-6 {
        >a {
            figure {
                border-radius: 0.7vw;
            }

        }

        padding: 0 1.4vw;
    }
}


@media (max-width:1229px) {
    .wrap-home .sl-5 {

        .sl-5-1 {
            height: auto;

            >.col-6 {
                padding: 0;
                width: 100%;
            }
        }
    }

}


@media (max-width: 992px) {
    .wrap-home .sl-2 .row {
        height: auto;

        .col-4 {
            width: 100% !important;
        }

    }

    .wrap-home .sl-5 {

        .row {
            height: auto;

            .col-6 {
                width: 100%;

                .row {
                    height: auto;

                    .col-6:nth-child(odd) {
                        height: 580px !important;
                    }

                    .col-6:nth-child(even) {
                        height: 680px !important;

                        .ct {
                            padding: 15px;
                        }
                    }
                }

            }
        }
    }

    .wrap-home .sl-3 .content-up {
        .ct-box-sli-sl3 {
            border-radius: 0;
            width: 100%;

            >.row .col:nth-child(1) {
                padding-top: 30px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .txt-big-des {
            width: 0%;
        }
    }

    .wrap-home .sl-9 .row {
        height: auto;

        .col-6 {
            width: 100%;
        }

        .col-6:nth-child(2) {
            padding-bottom: 40px;

            ul {
                padding-left: 0;
            }
        }

        .col-6:nth-child(1) {
            .socials-contact {
                height: 80%;
            }

            .tab-head-sm {
                left: 10px;

                img {
                    width: 180px;
                }

                p {
                    font-size: 16px;
                }
            }
        }

    }

    .wrap-home .sl-4 .row {
        .col-6 {
            width: 100%;
        }
    }

    .wrap-home .sl-10 .container {

        .row {
            margin: 0;

            .col {
                width: 33.3333333%;
            }

            .col:nth-child(n+4) {
                margin-top: 12px;

            }
        }
    }

    .wrap-home .sl-6 .container .faqs {
        .faq {
            .tab-ques {
                position: relative;

                svg {
                    position: absolute;
                    z-index: 2;
                    right: 0;
                    top: 0px;
                    // width: 1.2em !important;
                    // height: 1.2em !important;
                }
            }
        }
    }

    .wrap-home .sl-7 .container {
        .row {
            .col-6 {
                width: 100%;
            }

            >.col-6:nth-child(2) {
                margin-top: 20px;

                .row {
                    margin: 0;

                    .col-6 {
                        padding: 0
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .wrap-home .sl-2 .row .col-4:nth-child(2) .ct {
        h1 {
            font-size: 42px;
        }

        p {
            font-size: 14px;
            -webkit-line-clamp: 4;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    .wrap-home .sl-1 .swiper {
        .swiper-slide>div {
            height: 100%;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            >div:nth-child(1) {
                max-width: 100%;
                padding: 0 15px
            }

        }

        .btn-slide {
            display: none;
        }

    }

    .wrap-home .sl-10 .container {
        max-width: 100%;

        .row {
            .col {
                width: 100%;
                margin-top: 12px;

            }
        }
    }

    .wrap-home .sl-6 .container .faqs {
        max-width: 100%;

        .faq {
            margin: 5px;

            .tab-ques {

                svg {
                    width: 1.2em !important;
                    height: 1.2em !important;
                }
            }
        }
    }

    .wrap-home .sl-9 .row {
        .col-6:nth-child(1) {
            h3 {
                font-size: 42px;
            }

            .socials-contact {
                padding: 5px 0;

                li:nth-child(2) {
                    p {
                        font-size: 13px;
                    }
                }
            }
        }

        .col-6:nth-child(2) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .wrap-home .sl-3 {
        height: auto;

        >.row {
            .col-4:nth-child(2) .bg-conti>div {
                top: 15%;
            }
        }

        .content-up {
            height: auto;
            position: relative;

            .ct-box-sli-sl3 {
                border-radius: 0;
                width: 100%;

                >.row {
                    .col {
                        width: 100% !important;
                    }

                    .col:nth-child(1) {
                        padding-bottom: 30px;
                    }

                    .col:nth-child(2) .swiper {
                        height: 350px;
                    }
                }

            }

            .txt-big-des {
                width: 0%;
            }
        }

    }

    .wrap-home .sl-5 .sl-5-1 .col-6 .row .col-6:nth-child(1) {
        .box-sale {
            img {
                height: 39px;
            }

            p {
                font-size: 13.5px !important;
            }
        }
    }
}