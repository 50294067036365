.wrap-bannerfiforpage {
    position: relative;
    height: 350px;

    >img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .wrap-bannerfiforpage-content {
        position: absolute;
        height: 100%;
        left: 0;
        margin-left: 10%;
        display: flex;
        top: 0;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        z-index: 2;

        .titleOtherF {
            font-size: 42px;
            cursor: pointer;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
        }

        h1 {
                font-size: 42px;
                cursor: pointer;
                font-weight: bold;
                color: #fff;
                text-transform: uppercase;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: #fff;

            span {
                color: #C9C9C9;
                cursor: pointer;
            }
        }

    }


}

.wrap-bannerfiforpage::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.wrap-form-sign-mail {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 70px 0;

    .logo1,
    .logo2 {
        position: absolute;
    }

    .logo2 {
        left: 17%;
        top: 33px;
    }

    .logo1 {
        right: 0;
        bottom: 0;
    }

    .form-box {
        position: relative;
        width: 58%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // padding: 40px 70px;
        height: 508px;
        background: rgba(0, 58, 32, 0.08);
        border-radius: 20px;
        text-align: center;

        h6 {
            font-size: 40px;
            color: #191825;
            margin-bottom: 79px;
            font-weight: 600;
        }

        >p {
            font-size: 21px;
            color: rgba(255, 0, 0, 0.5);
            font-weight: 300;
            margin-bottom: 30px;
            text-transform: uppercase;

        }

        >.d-flex {
            flex-wrap: wrap;
            width: 90%;
            justify-content: center;

            form {
                flex: 0 0 auto;
                width: 69.999999%;

                .ant-input-affix-wrapper-lg {
                    border-radius: 20px;

                    .ant-input-prefix {
                        svg {
                            color: rgba(25, 24, 37, 0.7);
                            margin: 0 4px;
                        }
                    }

                    input {
                        height: 40px;
                        width: 70%;
                    }

                    input:focus,
                    input:hover {
                        border: none;
                    }
                }




            }

            .btn-sub {
                flex: 0 0 auto;
                width: 25.999999999%;

                >div {
                    width: 100% !important;
                }

                button {
                    width: 150px;
                    height: 56px;
                    border-radius: 20px;
                    padding: 0 15px;
                    color: #fff;
                    font-weight: 400;
                    font-size: 17px;
                    background: #A60A24;
                }

                button:hover {
                    background: rgba(166, 10, 36, 0.6);
                    transition: all 0.4s ease-in-out;
                }
            }





        }
    }
}

//
.tabicons.container {
    padding: 45px 0;

    ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            padding: 10px 33px;
        }
    }
}

.wrap-libraries {
    padding: 0 10px;

    .l-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        .txt-ti {
            text-align: center;
        }

        // .nav-wrap {
        //     display: flex;

        //     >div {
        //         margin: 0 6px;
        //         width: 60px;
        //         height: 60px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         border: rgba(0, 0, 0, 0.4);
        //         border-radius: 50%;
        //         box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        //         svg {
        //             color: #A60A24;
        //             width: 24px;
        //             height: 24px;
        //         }
        //     }

        //     >div:hover {
        //         background: #A60A24;
        //         transition: all 0.7s ease-in-out;
        //         cursor: pointer;

        //         svg {
        //             color: #fff;
        //         }
        //     }
        // }
    }

    .l-2 {
        margin-top: 25px;

            div {
                .row {
                    .col-4 {
                        padding: 0;

                            .wrap-img-in-lib {
                                padding: 12px;
                                border-radius: 10px;
                                .ab-pl {
                                    position: absolute;
                                    padding: 0 17px;
                                    z-index: 2;
                                    display: flex;
                                    justify-content: end;
                                    align-items: end;
                                    width: 95%;
                                    top: 0;
                                    padding-bottom: 30px;

                                    >p {
                                        color: #fff;
                                        font-weight: 600;
                                        font-size: 25px;
                                    }

                                    .ab-pl-ic {
                                        border: 3px solid #fff;
                                        border-radius: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 60px;
                                        height: 60px;

                                        svg {
                                            color: #fff;
                                            width: 19px;
                                            height: 19px;
                                            margin-left: 3px;
                                        }
                                    }

                                    .ab-pl-ic:hover {
                                        background: #61b91c;
                                        transition: all 0.7s ease-in-out;
                                    }
                                }

                                div {
                                    position: relative;
                                    overflow: hidden;
                                    height: 100%;
                                    border-radius: 10px;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        position: relative;
                                        transition: all 0.85s;
                                        -webkit-transition: all 0.85s;
                                    }
                                }


                            }

                            .wrap-img-in-lib:hover {
                                img {
                                    transform: scale(1.1);
                                } 
                            }
                            .bu-bs {
                                cursor: pointer;
                                .wrap-img-in-lib {
                                    position: relative;
                                }
                                
    
                                .lib-big {
                                    height: 500px;
                                }
    
                                .lib-sm {
                                    height: 277px;
                                }
                            }

                            


                    }
                }
            }

    }
}

@media (max-width: 1189px) {

    .wrap-form-sign-mail .form-box>.d-flex {

        form,
        .btn-sub {
            flex: 0 0 auto;
            width: 100%;
        }

        .btn-sub {
            margin-top: 20px;
        }
    }
}

@media (max-width: 992px) {

    .wrap-form-sign-mail {
        .form-box {

            width: 80%;
            padding: 0 25px;

        }

        .logo2 {
            left: 1%;
            top: 33px;
        }

        .logo1 {
            right: -20%;
            bottom: 0;
        }
    }
    .wrap-libraries .l-2 .row .col-4:nth-child(2) { 
        padding-right: 0 !important;
    }


    .wrap-libraries .l-2  .row .col-4:nth-child(1),
    .wrap-libraries .l-2  .row .col-4:nth-child(2) {
        flex: 0 0 auto;
        width: 49.99999999%;
    }
    .wrap-libraries .l-2  .row .col-4:nth-child(3) {
        flex: 0 0 auto;
        width: 99.99999999%;
        margin-top: 10px;
    }

}

@media (max-width: 480px) {

    .wrap-form-sign-mail {
        .form-box {

            height: 650px;

        }

        .logo1 {
            display: none;
        }
    }

}

@media (max-width: 768px) {

    .wrap-form-sign-mail {
        .logo1 {
            display: none;
        }
    }


    .wrap-libraries .l-2 .row .col-4:nth-child(2) { 
        padding: 0 !important;
    }


    .wrap-libraries .l-2  .row .col-4:nth-child(1),
    .wrap-libraries .l-2  .row .col-4:nth-child(2),
    .wrap-libraries .l-2  .row .col-4:nth-child(3) {

        flex: 0 0 auto;
        width: 99.99999999%;
    }
    .wrap-libraries .l-2  .row .col-4:nth-child(2),
    .wrap-libraries .l-2  .row .col-4:nth-child(3) {
        margin-top: 10px;
    }

}


@media (min-width: 1440px) {

    .wrap-libraries {
            padding: 0 0.9px;
            .l-2 div .row .col-4 .bu-bs {
                .lib-big {
                        height: 34vw;
                }
                .lib-sm {
                        height: 20vw;
                }
        
                .wrap-img-in-lib {
                    padding: 0.8vw;
                    border-radius: 0.7vw;
                    .ab-pl .ab-pl-ic {
                        width: 4vw;
                        height: 4vw;
                        svg {
                            width: 1.4vw;
                            height: 1.4vw;
                        }
                    }
                }
            } 
    }

    .wrap-bannerfiforpage {
        height: 24.3vw;
        .wrap-bannerfiforpage-content {
            h1 {
                font-size: 2.9vw;

            }
            p {
                font-size:1.1vw;
            }
        }

      }
    
}