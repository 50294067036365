.wrap_tour {
    >.tour-1 {
        margin: 100px 0 50px 0;

        .container {
            .row {
                .col-6:nth-child(1) {
                    padding-top: 90px;

                    h2 {
                        font-size: 42px;
                        font-weight: bold;
                        letter-spacing: 0.6px;
                        line-height: 48px;
                    }

                    ul {
                        margin-top: 65px;

                        li {
                            margin-bottom: 18px;

                            p:nth-child(1) {
                                font-size: 23px;
                                font-weight: 600;

                                span {
                                    font-weight: 400;
                                    margin-right: 9px;
                                }
                            }

                            p:nth-child(2) {
                                max-width: 70%;
                                font-size: 16px;
                                color: #696969;
                                line-height: 22px;
                                font-weight: 400;
                            }
                        }

                    }
                }

                .col-6:nth-child(2) {
                    .wrap-out {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        >svg:nth-child(1),
                        >svg:nth-child(2) {
                            position: absolute;
                            top: -50px;
                            right: 0;
                            z-index: 1;

                        }

                        >svg:nth-child(2) {
                            right: 190px;

                        }

                        .tab-short,
                        .tab-trans {
                            position: absolute;
                            z-index: 3;
                        }

                        .tab-trans {
                            right: 10px;
                            top: 80px;
                        }

                        .si-2 {
                            top: -40px;
                            left: -30px;
                        }

                        .si-1 {
                            bottom: -40px;
                            right: -30px;

                        }

                        .si-1,
                        .si-2 {
                            height: 500px;
                            position: absolute;
                            z-index: 3;
                            width: 48%;
                            object-fit: cover;
                            border-radius: 200px;
                            border: 20px solid #fff;
                        }

                        .si-3 {
                            width: 65%;
                            border-radius: 200px;
                            position: relative;
                            object-fit: cover;
                            z-index: 2;
                            height: 700px;
                        }

                        .tab-short {
                            width: 158px;
                            height: 168px;
                            background: #61b91c;
                            border-radius: 0 70px 0 70px;
                            padding-top: 20px;
                            padding-left: 40px;
                            bottom: -17px;
                            left: 70px;

                            p {
                                color: #fff;
                                font-weight: 600;
                                font-size: 28px;
                                font-family: "Baloo 2", sans-serif;
                            }
                        }

                    }
                }

            }
        }


    }

    >.tour-2 {
        .container:nth-child(1) {
            padding: 60px 0;

            .row {
                .col-6:nth-child(1) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    h2 {
                        font-size: 42px;
                        font-weight: bold;
                        letter-spacing: 0.6px;
                        line-height: 48px;
                    }

                    p {
                        font-size: 17px;
                        color: #696969;
                        font-weight: 300;
                        max-width: 70%;
                        margin: 25px 0 30px 0;
                    }


                }

                .col-6:nth-child(2) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .tab-t-c {
                        position: relative;

                        margin: 0 25px;
                        height: 400px;
                        border-radius: 150px;
                        width: 45%;
                        cursor: pointer;

                        >img {
                            position: relative;
                            width: 100%;
                            border-radius: 150px;
                            height: 100%;
                            object-fit: cover;
                        }

                        .bgtl-content {
                            position: absolute;
                            z-index: 2;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            top: 0;
                            left: 0;

                            h1 {
                                font-size: 70px;
                                margin-bottom: 40px;
                                font-weight: bold;
                                color: #fff;
                            }

                            p {
                                color: #fff;
                                font-size: 17px;
                                font-weight: 400;
                                letter-spacing: 0.5px;
                            }
                        }

                    }

                    .tab-t-c::before {
                        content: "";
                        background: rgba(0, 0, 0, 0.7);
                        position: absolute;
                        top: 0;
                        border-radius: 150px;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                    }

                    .tab-t-c:hover::before {
                        transition: all 0.7s ease-in-out;
                        background: rgba(255, 255, 255, 0.3);
                    }

                    .active:hover::before,
                    .active::before {
                        background: rgba(97, 185, 28, 0.9);
                        ;
                    }

                }
            }
        }

        .container:nth-child(2) {
            .s2-1 {
                margin-bottom: 50px;

                .col-3 {
                    width: 19%;
                    padding: 0;
                    cursor: pointer;

                    >div {
                        background: #fff;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                        transition: all 0.8s ease-in-out;
                        border-radius: 10px;
                        padding: 10px 15px;
                        height: 90px;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        justify-content: flex-start;
                        flex-direction: column;

                        p:nth-child(1) {
                            font-weight: bold;
                            font-size: 18px;
                            margin-bottom: 2px;
                            text-transform: uppercase;
                        }

                        p:nth-child(2) {
                            font-weight: 400;
                            font-size: 13.5px;
                        }
                    }

                }

                .col-3:nth-child(1) {
                    margin-left: 0;

                }

                .col-3:nth-child(n + 2) {
                    margin-left: 17px;
                }

                .activec {
                    color: #fff;

                    >div {
                        background: #61b91c;
                    }
                }

            }

            .s2-2 {
                margin-bottom: 70px;

                .col-4 {
                    >h5 {
                        font-weight: 700;
                        font-size: 25px;
                        text-transform: uppercase;
                    }

                    ul {
                        margin-top: 30px;
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        padding-left: 15px;

                        li {
                            flex: 0 0 auto;

                            .ct-routes {
                                border-left: 2px dashed #61b91c;
                                position: relative;

                                .lc {
                                    background: #fff;
                                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                    width: 45px;
                                    height: 45px;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    position: absolute;
                                    left: -23px;

                                    svg {
                                        width: 30px;
                                        height: 30px;
                                        color: #61b91c;
                                    }
                                }

                                >.ct-lc {
                                    padding-left: 35px;
                                    padding-bottom: 55px;

                                    h6 {
                                        margin-bottom: 5px;
                                        text-transform: capitalize;
                                        font-weight: 600;
                                        font-size: 16px;
                                    }

                                    p {
                                        color: #333;
                                        font-size: 15px;
                                        max-width: 60%;
                                    }
                                }

                            }
                        }

                        li:nth-child(n+2) {
                            .ct-routes {
                                >.ct-lc {}
                            }
                        }

                        li:last-child .ct-routes {
                            border-left: 1px solid transparent;
                        }
                    }
                }

                .col-8 {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >img {
                        width: 100%;
                    }
                }
            }

            .s3-3 {
                margin-bottom: 70px;

                .col-6:nth-child(1) {
                    >h5 {
                        font-weight: 700;
                        font-size: 25px;
                        text-transform: uppercase;
                    }

                    >p:nth-child(2) {
                        font-size: 19px;
                        font-weight: 600;
                        color: #111111;
                        margin-top: 18px;
                    }

                    >p:nth-child(3) {
                        font-size: 15.5px;
                        font-weight: 300;
                        margin-top: 5px;
                        color: #111111;
                        max-width: 90%;
                    }
                }

                .col-6:nth-child(2) {
                    >div {
                        >figure {
                            height: 250px;
                        }

                        figure {
                            width: 100%;
                            border-radius: 10px;
                            border-radius: 10px;
                            overflow: hidden;
                            cursor: pointer;
                        }

                        figure:hover {
                            img {
                                transform: scale(1.1);
                            }
                        }

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                            transition: all 0.8s;
                            -webkit-transition: all 0.8s;

                        }

                        .row {
                            margin: 10px auto;

                            .col-6:nth-child(2) {
                                padding-right: 0;
                                padding-left: 5px;

                                >figure {
                                    height: 200px;
                                }

                                figure:nth-child(2) {
                                    margin-top: 10px;
                                }

                            }

                            .col-6:nth-child(1) {
                                padding-left: 0;
                                padding-right: 5px;

                                img {
                                    height: 410px;
                                }

                            }
                        }
                    }


                }
            }

            .s4-4 {
                padding-bottom: 90px;

                // justify-content: space-between;
                h5 {
                    font-weight: 700;
                    font-size: 25px;
                    text-transform: uppercase;
                    margin-bottom: 30px;

                }

                .col-4 {
                    display: flex;
                    width: 32%;
                    background: #fff;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    border-radius: 0 10px 10px 0;
                    padding-left: 0;


                    >img {
                        width: 35%;
                        object-fit: cover;
                        border-radius: 10px 0 0 10px;
                    }

                    .s4-4-cts-b {
                        padding: 9px 0 9px 20px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;

                        >div {

                            p:nth-child(1) {
                                font-size: 19px;
                                font-weight: 600;
                                margin-bottom: 5px;
                            }

                            h4 {
                                font-weight: bold;
                                color: #61b91c;
                                letter-spacing: 0.5px;
                                font-size: 25px;
                                margin-bottom: 3px;
                            }

                            p:nth-child(3) {
                                font-size: 17px;
                                font-weight: 600;

                            }

                            p:nth-child(4) {

                                font-size: 14px;
                                font-weight: 300;
                                margin: 7px 0 15px 0;
                                color: #3e3e3e;

                                span {
                                    font-weight: 600;
                                }
                            }
                        }



                        .btn-main {
                            height: 39px;
                            width: 100px;
                        }

                    }

                }

                .col-4:nth-child(n + 3) {
                    margin-left: 20px;

                }

            }
        }
    }

    >.tour-3 {
        .swiper {

            .swiper-slide {
                position: relative;

                >img {
                    width: 100%;
                    height: 700px;
                    position: relative;
                    object-fit: cover;
                }

                >div {
                    position: absolute;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 30%;

                    >div:nth-child(1) {
                        width: 30%;

                        h3 {
                            font-weight: bold;
                            font-size: 58px;
                            text-transform: uppercase;
                            color: #fff;
                            letter-spacing: 1.2px;
                        }

                        p {
                            font-size: 27px;
                            font-weight: 300;
                            color: #fff;
                        }
                    }

                    >div:nth-child(2) {
                        background: rgba(255, 255, 255, 0.5);
                        width: 35%;
                        padding: 45px 35px;
                        border-radius: 20px;

                        p:nth-child(1) {
                            font-weight: bold;
                            font-size: 23px;
                            text-transform: uppercase;
                            color: #111;
                            letter-spacing: 1.2px;
                        }

                        p:nth-child(2) {
                            font-size: 18px;
                            text-align: justify;
                            font-weight: 300;
                            margin-top: 15px;
                            color: #353535;
                            padding-right: 10px;
                        }
                    }
                }
            }

            .swiper-slide::before {
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                z-index: 1;
                height: 100%;
                background: rgba(0, 0, 0, 0.6);
                position: absolute;
            }

            .btn-slide {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                p {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 20px;
                    transform: rotate(90deg);
                    cursor: pointer;

                }

                p:nth-child(2) {
                    margin-right: 15px;
                }
            }
        }
    }

    >.tour-4 {
        padding: 110px 0;

        .row {
            .col-6:nth-child(1) {
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding-left: 70px;

                >h1 {
                    font-size: 42px;
                    font-weight: bold;
                    letter-spacing: 0.6px;
                    line-height: 48px;
                }

                .t4-ct {
                    >p {
                        font-weight: 400;
                        line-height: 24px;
                        font-size: 15px;
                        color: #111
                    }

                    >div {
                        display: flex;
                        align-items: center;
                        margin: 25px 0 20px 0;

                        >img {
                            width: 70px;
                            height: 70px;
                            border-radius: 50%;
                        }

                        div {
                            margin-left: 15px;

                            p:nth-child(1) {
                                font-size: 19px;
                                font-weight: 600;
                            }

                            p:nth-child(2) {
                                font-size: 16px;
                                font-weight: 400;
                                color: #696969;
                            }
                        }
                    }
                }

                .sli-btn-cus {
                    margin: 20px 0;

                    svg {
                        // margin-top: 3px;
                        color: #696969;
                        cursor: pointer;
                    }

                    >div {
                        margin: 0 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        p {
                            font-size: 17px;
                            color: #353535;
                            font-weight: 500;
                        }
                    }
                }
            }

            .col-6:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: center;

                .swiper {
                    position: relative;
                    width: 53%;
                    height: 420px;

                    .swiper-slide {
                        border-radius: 10px;

                        img {
                            width: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1189px) {
    .wrap_tour>.tour-1 .container .row .col-6:nth-child(2) .wrap-out .tab-short {
        left: 20px;
    }
}

@media (max-width: 1400px) {
    .wrap_tour {
        > .tour-2 {
            .container:nth-child(2) {
                .s2-1 {
                    .col-3 {
                        width: 23%;
                        >div {
                            p:nth-child(2) {
                                font-size: 13px;
                            }
                        }
                    }
                }
            } 
        } 
    } 
    
}

@media (min-width: 1440px) {
    .wrap_tour {
        .tour-1 {
            .container {
                .row {
                    .col-6:nth-child(1) {
                        h2 {
                            font-size: 3.7vw;
                            line-height: 4.1vw;
                        }

                        ul {
                            li {
                                margin-bottom: 1.7vw;

                                p:nth-child(1) {
                                    font-size: 1.5vw;
                                }

                                p:nth-child(2) {
                                    font-size: 0.99999vw;
                                    line-height: 1.3vw;
                                }
                            }
                        }
                    }

                    .col-6:nth-child(2) {
                        .wrap-out {

                            .si-2,
                            .si-1 {
                                height: 34vw;
                            }

                            .si-2,
                            .si-1,
                            .si-3 {
                                border-radius: 22vw;
                            }

                            .si-3 {
                                height: 48vw;
                            }

                            .tab-short {
                                width: 10.7vw;
                                height: 12.6vw;
                                padding-top: 1.8vw;
                                padding-left: 2.7vw;
                                border-radius: 0 4vw 0 4vw;
                                bottom: -3vw;
                                left: 5vw;

                                p {
                                    font-size: 1.9vw;
                                }
                            }
                        }
                    }
                }
            }
        }

        .tour-2 {
            .container:nth-child(1) {
                .row {
                    .col-6:nth-child(1) {
                        h2 {
                            font-size: 3.5vw;
                            line-height: 4.1vw;
                        }

                        p {
                            font-size: 1.1vw;
                            margin: 1.2vw 0 1.4vw 0;
                        }
                    }

                    .col-6:nth-child(2) {
                        .tab-t-c {
                            margin: 0 1.8vw;
                            height: 28vw;

                            img {
                                border-radius: 18vw;
                            }

                            .bgtl-content {
                                h1 {
                                    font-size: 4.5vw;
                                }

                                p {
                                    font-size: 1.2vw;
                                }
                            }
                        }

                        .active::before,
                        .tab-t-c::before {
                            border-radius: 18vw;
                        }
                    }
                }
            }

            .container:nth-child(2) {
                .s2-1 {
                    .col-3 {
                        >div {
                            border-radius: 0.8vw;
                            padding: 0.8vw 1vw;
                            height: 6.5vw;

                            p:nth-child(1) {
                                font-size: 1.2vw;
                            }

                            p:nth-child(2) {
                                font-size: 0.9vw;
                            }
                        }
                    }

                    .col-3:nth-child(n+2) {
                        margin-left: 1.2vw;
                    }
                }

                .s2-2 {
                    .col-4 {
                        >h5 {
                            font-size: 1.5vw;
                        }

                        ul {
                            li {
                                .ct-routes {
                                    border-left: 0.1777777vw dashed #61b91c;

                                    .ct-lc {
                                        padding-left: 2.8vw;
                                        padding-bottom: 4vw;

                                        h6 {
                                            font-size: 1.1vw;
                                        }

                                        p {
                                            font-size: 1vw;
                                        }
                                    }

                                    .lc {
                                        width: 3.2vw;
                                        height: 3.2vw;
                                        left: -1.6vw;

                                        svg {
                                            width: 2vw;
                                            height: 2vw;
                                        }
                                    }
                                }
                            }

                        }
                    }
                }

                .s3-3 {
                    .col-6:nth-child(1) {
                        >h5 {
                            font-size: 1.5vw;
                        }

                        >p:nth-child(2) {
                            font-size: 1.3vw;
                            margin-top: 1.3vw;
                        }

                        >p:nth-child(3) {
                            font-size: 1.099999vw;
                        }
                    }

                    .col-6:nth-child(2) {
                        >div {
                            img {
                                border-radius: 1vw;
                            }

                            >figure {
                                height: 17vw;
                            }

                            .row {
                                margin: 0.7vw auto;

                                .col-6:nth-child(1) {
                                    padding-right: 0.4vw;

                                    img {
                                        height: 28.5555vw;
                                    }
                                }

                                .col-6:nth-child(2) {
                                    padding-left: 0.4vw;

                                    figure {
                                        height: 13.8999999vw;
                                    }

                                    figure:nth-child(2) {
                                        margin-top: 0.69999vw;
                                    }
                                }
                            }
                        }
                    }
                }

                .s4-4 {
                    h5 {
                        font-size: 1.5vw;
                        margin-bottom: 2.8vw;
                    }

                    .col-4:nth-child(n + 3) {
                        margin-left: 1.6vw;


                    }

                    .col-4 {
                        border-radius: 0 0.8vw 0.8vw 0;

                        img {
                            border-radius: 0.8vw 0 0 0.8vw;
                        }

                        .s4-4-cts-b {
                            padding: 0.8vw 0 0.8vw 1.2vw;

                            >div {
                                p:nth-child(1) {
                                    font-size: 1.3vw;
                                }

                                p:nth-child(3) {
                                    font-size: 1.2vw;
                                }

                                p:nth-child(4) {
                                    font-size: 0.99999vw;
                                }

                                h4 {
                                    font-size: 1.7vw;
                                    margin-bottom: 0.1vw;
                                }


                            }
                            .btn-main {
                                height: 2.4vw;
                                width: 5.7vw;
                            }
                        }
                    }
                }
            }
        }
        .tour-3 {
            .swiper {
                .btn-slide {
                    p {
                        font-size: 1.1vw;
                    }
                }
                .swiper-slide {
                    > img {
                        height: 39vw;
                    }
                    > div {
                        > div:nth-child(1) {
                            h3 {
                                font-size:3.7vw;
                            }
                            p {
                                font-size: 1.8vw;
                            }
                        }
                        > div:nth-child(2) {
                            padding: 2.9vw 2.2vw;
                            border-radius: 1.4vw;
                            p:nth-child(1) {
                                font-size:1.6vw;
                            }
                            p:nth-child(2) {
                                font-size:1.2vw;
                                margin-top: 0.8vw;
                            }

                        }
                    }
                }
            }
        } 
    }

}

@media (max-width:992px) {
    .wrap_tour {
        .row {
            margin: 0
        }
        .tour-1 {
            margin: 60px 0 50px 0;

            .container {
                .row {
                    .col-6 {
                        width: 100%;
                    }

                    .col-6:nth-child(1) {
                        padding-top: 0;
                    }

                    .col-6:nth-child(2) {
                        margin: 85px 0;
                    }
                }
            }
        }
        .tour-2 {
            .container:nth-child(1) {
                .row {
                    .col-6 {
                        width: 100%;
                    }
                    .col-6:nth-child(2) {
                        margin-top: 55px;
                    }
                }
            }
            .container:nth-child(2) {
                .s2-1 {
                    .col-3 {
                        > div {
                            height: 105px;
                            p:nth-child(2) {
                                font-size: 12px;
                            }
                        }
                    }
                }
                .s2-2 {
                    .col-4, .col-8 {
                        width: 100%;
                    }
                }
                .s3-3 {
                    .col-6 {
                        width: 100%;
                        
                    }
                        figure {
                            height: 200px !important;
                            
                        }

                        >.col-6:nth-child(2)  {
                            margin-top: 10px;
                            >div {
                                .row {
                                    .col-6:nth-child(2) {
                                        padding-left: 0;
                                        figure:nth-child(1) {
                                            margin-top: 10px;
                                        }
                                    }
                                    .col-6:nth-child(1) {
                                        padding-right: 0;
                                    }
                                }
                            }
                        }
                }
                .s4-4 {
                    .col-4 {
                        width: 100%;
                    }
                    .col-4:nth-child(n+3) {
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
            } 
        }
        .tour-3 .swiper {
            .btn-slide  {
                display: none;
            }
            .swiper-slide {
                > div {    
                    top: 0;
                    height: 100%;
                    width: 100%;
                    padding: 20px 0;

                    div:nth-child(2) {
                        display: none;
                    }
                    div:nth-child(1) {
                        width: 100%;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        h1 {
                            font-size: 32px;
                        }
                    }
                }
            }
        } 
    }

}

@media (max-width: 580px) {
    .wrap_tour {
        .tour-2 {
            .container:nth-child(1) {
                .row {
                   .col-6:nth-child(1) {
                     padding-left: 30px;
                   }
                   .col-6:nth-child(2) {
                    //  padding-left: 30px;
                    flex-wrap: wrap;
                    padding: 0 20px;
                     .tab-t-c {
                        width: 100%;
                        flex: 0 0 auto;
                     }
                     .tab-t-c:nth-child(2) {
                        margin-top: 20px;
                     }
                   }
                }
            }
        }
    }
    
}

@media (max-width: 768px) {

    .wrap_tour {
        .tour-1 {
            .container {
                .row {
                    .col-6:nth-child(2) {
                        .wrap-out {
                            .tab-short {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
        .tour-2 {
            .container:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .s2-1 {
                    .col-3 {
                        width: 45%;
                        margin: 10px !important;
                        
                        > div {

                        }
                    }
                }
            } 
        }
    }
}

@media (max-width: 480px) {

    .wrap_tour {
        >.tour-1 {
            overflow: hidden;

            .container {
                .row {
                    .col-6:nth-child(2) {
                        .wrap-out {
                            svg:nth-child(2) {
                                right: 20px;
                            }

                            svg:nth-child(1) {
                                right: -50%;
                            }

                            .si-3 {
                                width: 100%;
                            }

                            .si-2 {
                                left: 0;

                                top: 7%;
                            }

                            .si-1 {
                                top: 42%;
                                left: 0;
                            }

                            .si-1,
                            .si-2 {
                                height: 220px;
                            }

                            .tab-trans {
                                width: auto;
                                top: 0;
                                right: 0;

                                svg {
                                    width: 20px;
                                    height: 20px;
                                }

                                p {
                                    font-size: 15px;
                                    margin: 0 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .tour-2 {
            .container:nth-child(2) {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .s2-1 {
                    .col-3 {
                        width: 100%;
                        margin: 10px 0 !important;                        
                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;

                        }
                    }
                }
            } 
        }
    }
}