.wrapHeader {
    padding: 1% 2%;

    .maxh {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tabbar {
            display: flex;
            align-items: center;

            >img {
                width: 172px;
                height: auto;
                cursor: pointer;
            }
        }

        .icons-tab-right {
            ul {
                li {
                    display: inline-block;

                    svg {
                        width: 25px;
                        height: 25px;
                    }

                    padding: 20px;

                }

                // li:nth-child(1) {
                //     position: relative;

                //     >.circle-sm-tag {
                //         position: absolute;
                //         border-radius: 100%;
                //         background-color: #ff1100;
                //         width: 22px;
                //         height: 22px;
                //         display: flex;
                //         align-items: center;
                //         justify-content: center;
                //         right: 8px;
                //         top: 8px;

                //         p {
                //             color: #fff;
                //             font-size: 10px;
                //             font-weight: 500;
                //         }
                //     }
                // }

                li:nth-child(1) {
                    padding-right: 0;

                }
            }
        }
    }

}

.menus {
    margin-left: 20px;

    li {
        display: inline-block;
        padding: 0 36px;

        a {
            text-decoration: none;
            color: rgba(17, 17, 17, 0.6);
            font-weight: 500;
            font-size: 14px;
            text-transform: uppercase;
            position: relative;
            padding-bottom: 5px;
            transition: all 0.85s;
        }

        a::before {
            content: "";
            width: 0;
            height: 2px;
            border-radius: 10px;
            position: absolute;
            bottom: 0;
            background: #61b91c;
            transition: all 0.85s;
        }

        a:hover::before {
            width: 100%;
        }

        a:hover {
            color: #61b91c;
        }

        .active::before {
            width: 100%;
        }

        .active {
            color: #61b91c;
        }



    }
}



.ant-drawer .ant-drawer-header {
    display: none;
}

.ant-drawer .ant-drawer-body {
    padding: 0;
}

.ant-drawer {
    .drawer-drag-right-menu {
        position: relative;

        >.imgbg {
            width: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .moveic {
            position: absolute;
            top: 15px;
            left: 10px;

            svg {
                color: #61b91c;
                width: 27px;
                height: 27px;
                cursor: pointer;

            }
        }

        .drawer-drag-rm-content {
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            justify-content: flex-start;
            padding: 40px 25px;
            overflow: hidden;

            .drawer-drag-content-logo {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 160px;
                    height: 66px;
                }
            }

            >p {
                font-size: 14px;
                font-weight: 300;
                color: #000000;
                padding-right: 12px;
                margin: 40px 0;
            }

            >.infos {
                li {
                    margin-bottom: 13px;

                    h6 {
                        color: #61b91c;
                        font-weight: 600;
                        font-size: 16px;
                        margin-bottom: 5px;
                    }

                    p {
                        padding-right: 80px;
                        font-size: 15px;
                        color: #414141;
                    }
                }
            }

            >.ic-circle-infos {
                display: flex;
                margin-top: 60px;

                li {
                    width: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    height: 48px;
                    background: #61b91c;

                    svg {
                        color: #fff;
                        width: 20px;
                        height: 20px;
                    }
                }

                li:nth-child(n + 2) {
                    margin-left: 15px;
                }

                li:hover {
                    // box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                    border: 2.5px solid #fff;
                    transition: 0.7s all ease-in-out;
                }
            }

        }
    }
}

.wrapFooter {
    text-align: center;
    background: #151515;
    padding: 50px 0 20px 0;
    position: relative;

    .bg-footer {
        position: absolute;
        top: 0px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.6117647059), rgba(0, 0, 0, 0.8), #000000);

        img {
            width: 100%;
            height: 100%;
            opacity: 0.07;
            vertical-align: middle;
        }
    }

    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        li {
            a {
                text-decoration: none;
            }
        }
    }

    .container {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        >h4 {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.8);
            font-weight: 700;
        }

        .space1 {
            margin-top: 20px;
            text-decoration: underline;
        }

        >.logom {
            width: 290px;
            height: 90px;
            margin-bottom: 30px;
        }

        .menus-footer {
            margin: 12px 0;

            li {
                padding: 25px;

                a {
                    color: #fff;
                }

                a:hover {
                    color: #61b91c;
                    transition: 0.5s all ease-in-out;
                }
            }
        }
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        margin: 5px 0;
    }

    .ics-social {
        margin: 25px 0;

        li {
            margin: 0 12px;
            border-radius: 50%;
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                svg {
                    width: 25px;
                    height: 25px;
                    color: #fff;
                }
            }
        }

        li:nth-child(2) {
            a {
                margin-bottom: 1.2px;
                margin-left: 0.2px;

                svg {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        li:nth-child(1) {
            a {
                margin-left: 1.5px;
                margin-bottom: 1.5px;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        li:nth-child(3) {
            a {
                margin-left: 1.4px;
                margin-top: 1.2px;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        li:nth-child(1) {
            background: #25D366;
        }

        li:nth-child(2) {
            background: linear-gradient(to right,
                    #833ab4, #fd1d1d, #fcb045);
        }

        li:nth-child(3) {
            background: #00af87;
        }
    }


    .last-content-f {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        ul {
            justify-content: center;
            align-items: center;

            li {
                padding: 0 4px;

            }

            li:nth-child(1) {
                img {
                    width: 51px;
                    height: 51px;
                }
            }

            li:nth-child(2) {
                img {
                    width: 55px;
                    height: 36px;
                }
            }

            li:nth-child(3) {
                img {
                    height: 55px;
                }
            }
        }
    }
}

.active-menu-footer {
    font-weight: 700;
    color: #61b91c !important;
}






.ic-socials,
.ic-scroll {
    position: fixed;
    z-index: 999;
    cursor: pointer;
    bottom: 20px;
}

.ic-socials {
    left: 35px;
    border-radius: 50%;

    background: #fff;
    border: 6px solid #61b91c;
    height: 55px;
    width: 55px;

    >div {
        width: 100%;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
        margin: 0 auto;
        background-color: #fff;

        .wave {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 9999px;
            background-color: rgba(97, 185, 28, 0.8);
            opacity: 0;
            z-index: -1;
            pointer-events: none;
        }

        svg {
            width: 27px;
            color: #61b91c;
            height: 27px;
        }
    }
}

.wave {
    animation: sonarWave 1.8s linear infinite;
}

@keyframes sonarWave {
    from {
        opacity: 0.6;
    }

    to {
        transform: scale(3);
        opacity: 0;
    }
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    50% {
        transform: rotate(0eg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.ic-scroll {
    right: 35px;
    border-radius: 50%;

    background: #61b91c;
    height: 55px;
    width: 55px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5px;

    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #fff;
        height: 45px;
        width: 45px;
        border-radius: 50%;

        svg {
            width: 24px;
            height: 24px;
            color: #fff;
            margin-top: 2px;
        }
    }
}

.ant-popover {
    .ant-popover-inner {
        padding: 0;
    }

    .wrapIcsC {
        >a:nth-child(1):hover {
            background: #d9d9d9;
            transition: all 0.4s ease-in-out;
        }

        >a {
            color: #333;
            display: flex;
            padding: 14px 16px;
            align-items: center;
            cursor: pointer;
            text-decoration: none;

            p {
                margin: 0;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            >div:nth-child(1) {
                height: 45px;
                width: 45px;

            }

            >div:nth-child(2) {
                margin-left: 15px;

                >p:nth-child(2) {
                    font-weight: 600;
                }

                >p:nth-child(1) {
                    font-weight: 800;
                }

            }

            p {
                font-size: 15px;
            }

            .fl {
                display: flex;
                margin-top: 5px;

                >p {
                    border-radius: 35px;
                    border-width: 1.5px;
                    border-style: solid;
                    padding: 4px 7px;
                    font-size: 14px;
                    border-color: #61b91c;
                    cursor: pointer;
                    font-weight: 600;
                }

                p:hover {
                    background: #61b91c;
                    color: #fff;
                    transition: all 0.4s ease-in-out;
                }

                p:nth-child(2) {
                    margin-left: 20px;
                }

            }

        }
    }
}


@media (max-width: 992px) {
    .menus {
        margin-left: 0;
        margin-bottom: 25px;

        li {
            display: block;
            padding: 4px 20px 4px 0;

            a {
                text-decoration: none;
                color: rgba(17, 17, 17, 0.6);
                font-weight: 600;
                font-size: 16px;
            }

            ;
        }
    }

    .ant-drawer {
        .drawer-drag-right-menu {
            .drawer-drag-rm-content {
                >p {
                    margin-bottom: 25px;
                }
            }
        }
    }

    .wrapFooter {
        .last-content-f {
            flex-direction: column;
        }
    }
}

@media (max-width: 768px) {
    .wrapFooter ul {
        flex-direction: column;
    }

    .wrapFooter .ics-social li:nth-child(n+2) {
        margin-top: 10px;
    }
}

@media (min-width: 1440px) {
    .ic-socials {
        width: 3.9vw;
        height: 3.9vw;
        border: 0.39999vw solid #61b91c;

        >div {
            svg {
                width: 2vw;
                height: 2vw;
            }
        }
    }
    

    .ant-popover {
        .wrapIcsC {
            >a {
                >div:nth-child(1) {
                    height: 2.3vw;
                    width: 2.3vw;

                }

                p {
                    font-size: 0.9vw;
                }

                padding: 0.8vw 1vw;

                .fl {
                    >p {
                        font-size: 0.8vw;
                    }
                }
            }
        }
    }

    .ic-scroll {
        width: 3.8vw;
        height: 3.8vw;
       >div {
        width: 3.2vw;
        height: 3.2vw;
        svg {
            width: 1.6vw;
            height: 1.6vw;
        }
       }
    }

    .wrapHeader .maxh .tabbar > img {
        width: 12vw;
    }

    .menus {
        margin-left: 1.7vw;
    
        li {
            padding: 0 2.3vw;
    
            a {
                font-size: 0.9vw;
                padding-bottom: 0.4vw;
            }
    
            a::before {
                height: 0.1vw;
                border-radius: 10px;
            }
    
    
    
        }
    }
    .wrapHeader .maxh .icons-tab-right ul li svg {
        width: 1.8vw;
        height: 1.8vw;
    }

    .wrapFooter {
        .container {
            > .logom {
                width: 25vw;
                height: 6.8vw;
                margin-bottom: 2.0833333333333335vw;
            }
            p {
                font-size: 1.1vw;
            }
            .space1 {
                margin-top: 2vw;
            }
            >h4 {
                font-size: 1.2vw;
            }
            .menus-footer {
                margin: 0.8vw 0;
                li {
                    a {
                        font-size:1.1vw;
                    }
                }
            }
            .ics-social {
                margin: 1.7vw 0;
                li {
                    margin: 0 0.7vw;
                    width: 3.3vw;
                    height: 3.3vw;
                }
                li:nth-child(1),
                li:nth-child(3)
                {
                    a {
                        margin-left: 0.14444vw;
                        margin-bottom: 0.1vw;
                        svg {
                            width: 1.7vw;
                            height: 1.7vw;
                        }
                    }

                }
                li:nth-child(2) {
                    a {
                        margin-left: 0.09vw;
                        margin-bottom: 0.0.1vw;
                        svg {
                            width: 1.6vw;
                            height: 1.6vw;
                        }
                    }

                }

            }
        }
        .last-content-f {
            margin-top: 4.5vw;
            ul {
                li {
                    padding: 0 0.3vw;
                }
                li:nth-child(1) img {
                    width: 3.6vw;
                    height: 3.6vw;
                }
                li:nth-child(2) img {
                    width: 3.7vw;
                    height: 2.7vw;
                }
                li:nth-child(3) img {
                    height: 3.7vw;
                }
            }
        }
    } 

}

.scrollHeaderMobile {
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -webkit-transition: all 0.7s ease-in-out;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 1000;
    background: #fff;
}