.demo-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0 0 0;
    img {
        width: 260px;
        height: 90px;
        padding: 15px;
    }
}

.sider-left {
    overflow: auto;
    height: 100vh;
    position: sticky !important;
    left: 0;
    top: 0;
}

@media (min-width: 992px) {
    .sider-left {
        max-width: 0px 
        !important;
    }
}

@media (min-width:1440px) {
    .demo-logo {
        img {
            width: 23vw;
            height: 9vw;
            padding: 2vw;
        }
    }
    
}