.wrap-form-book {
    .txt-mobile-form {

        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700 !important;
        margin-bottom: 0 !important;
        color: #f59e0c !important;
        margin-top: 20px;

    }

    >div {
        margin: 60px 0;

        .container {
            >h1 {
                font-size: 42px;
                font-weight: bold;

            }

            >p {
                font-size: 17px;
                font-weight: 300;
                color: #6A6A6A;
                max-width: 40%;
                margin-bottom: 20px;

            }

            .row {
                .col-4 {
                    ul {
                        li {
                            justify-content: space-between;
                            align-items: center;
                            padding: 17px 15px;
                            margin-top: 15px;
                            border: 1px solid rgba(138, 138, 138, 0.2);
                            border-radius: 10px;
                            transition: all 0.8s ease-in-out;

                            p {
                                text-transform: uppercase;
                                font-weight: 500;
                            }

                            svg {
                                width: 18px;
                                height: 18px;
                            }

                        }

                        .active-t {
                            background: #61b91c;
                            color: #fff;
                        }
                    }
                }

                .col-8 {
                    padding-left: 90px;

                    .tab-movement {
                        justify-content: space-between;
                        align-items: center;

                        >div {
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            p {
                                margin: 8px;
                                font-size: 15px;
                                color: #61b91c;
                            }

                            svg {
                                color: #61b91c;
                            }
                        }

                        .btn-main {
                            width: 178px;
                        }
                    }

                    .form-book {
                        .note-spec {
                            margin-top: 25px;
                            font-size: 15px;
                            color: #888888;
                            font-weight: 400;

                            span {
                                color: #333;
                                font-weight: 600;
                            }
                        }

                        .radiofix {
                            label {
                                margin-top: 20px;
                                width: 45%;
                                display: inline-block;

                                span:nth-child(2) {
                                    p:nth-child(1) {
                                        font-size: 15px;
                                    }

                                    p:nth-child(2) {
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #6A6A6A;
                                        margin-bottom: 15px;
                                    }

                                    img {
                                        width: 180px;
                                        height: 180px;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }

                        .form-ng {
                            flex-wrap: wrap;

                            >div {
                                margin-bottom: 0;
                                width: 49%;
                                flex: 0 0 auto;
                            }
                        }

                        .check-box-w {
                            display: flex;
                            flex-direction: column;

                            .ant-checkbox-wrapper {
                                margin-bottom: 20px;
                            }
                        }



                    }

                    .confirm-b {
                        h4 {
                            margin-top: 15px;
                            font-size: 22px;
                            font-weight: 700;
                        }

                        .et-tbal {
                            justify-content: space-between;
                            align-items: center;

                            padding: 17px 0;
                            border-bottom: 1px solid #d9d9d9;

                            .pri {
                                font-weight: 700;
                            }
                        }

                        .total {
                            justify-content: space-between;
                            padding: 20px 0;

                            p:nth-child(1) {
                                font-weight: 700;
                                font-size: 23px;
                                text-transform: uppercase;

                            }

                            >div {
                                display: flex;
                                flex-direction: column;
                                align-items: end;

                                p {
                                    font-weight: 700;
                                    font-size: 20px;
                                    font-family: "Poller One", serif;


                                }
                            }


                        }

                        .btnsm-wrap {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1440px) {
    .wrap-form-book {
        >div {
            margin: 3.8vw 0;

            .container {
                >h1 {
                    font-size: 2.4vw;
                }

                >p {
                    font-size: 1.1vw;
                    margin-bottom: 1.3vw;
                }

                .row {
                    .col-4 {
                        ul {
                            li {
                                padding: 1.3vw 1.2vw;
                                margin-top: 1.2vw;

                                p {
                                    font-size: 1.1vw;
                                }

                                svg {
                                    width: 1.2vw;
                                    height: 1.2vw;
                                }
                            }
                        }
                    }

                    .col-8 {
                        .ant-input-textarea-affix-wrapper {
                            height: 12.847222222222221vw !important;
                        }

                        .tab-movement {
                            >div {
                                p {
                                    font-size: 1vw;
                                    margin: 0.5555555555555556vw;
                                }

                                svg {
                                    width: 1.1111111111111112vw;
                                    height: 1.1111111111111112vw;
                                }
                            }
                        }

                        .form-book {
                            label {
                                font-size: 0.99999vw;
                            }

                            label::before {
                                font-size: 0.99999vw;
                            }

                            .ant-select-single {
                                height: 3.5vw;
                            }

                            .ant-form-item-explain-error {
                                font-size: 0.9vw;
                            }

                            .ant-picker {
                                height: 3.5vw;
                            }

                            .ant-picker,
                            .ant-input-number,
                            .ant-select-single {
                                font-size: 1vw;
                            }

                            .ant-input-number {
                                input {
                                    padding: 1.0416666666666667vw 0.7638888888888888vw;
                                }
                            }

                            .radiofix {
                                label {
                                    span:nth-child(2) {
                                        p:nth-child(1) {
                                            font-size: 1.07777vw;
                                        }

                                        p:nth-child(2) {
                                            font-size: 0.99999vw;
                                        }

                                        img {
                                            width: 13vw;
                                            height: 13vw;
                                        }
                                    }
                                }
                            }

                            .note-spec {
                                margin-top: 2vw;
                                font-size: 0.99999vw;
                            }

                            .ant-checkbox {
                                input {
                                    width: 1.1vw;
                                    height: 1.1vw;
                                }
                            }

                        }

                        .confirm-b {
                            h4 {
                                font-size: 1.4vw;
                            }

                            .et-tbal {
                                padding: 1.34444vw 0;

                                p {
                                    font-size: 1.1vw;
                                }
                            }

                            .total {
                                p:nth-child(1) {
                                    font-size: 1.4vw;
                                }

                                >div {
                                    p {
                                        font-size: 1.5vw;
                                    }
                                }
                            }

                            .ant-input {
                                padding: 1.1vw 0.8vw;
                                font-size: 0.9999vw;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .wrap-form-book {
        >div {
            .container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                >p {
                    max-width: 100%;
                    text-align: center;
                }

                .row {
                    width: 100%;

                    .col-4,
                    .col-8 {
                        width: 100%;

                    }

                    .col-8 {
                        padding-left: 10px;
                        margin-top: 25px;

                        .confirm-b {
                            .et-tbal {
                                flex-direction: column;

                                .pri {
                                    margin-top: 10px;
                                }
                            }

                            .total {
                                flex-direction: column;
                                align-items: center;

                                >div {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }

            }

        }
    }

}

@media (max-width: 480px) {
    .wrap-form-book {
        >div {
            .container {

                >p,
                >h1 {
                    text-align: left;
                }

                h1 {
                    width: 100%;
                }

                .row {

                    .col-8 {
                        .form-book {
                            .radiofix {
                                label {
                                    width: 100% !important;
                                }
                            }
                        }

                        .confirm-b {
                            .row {
                                margin: 0;
                            }
                        }
                    }

                    .col-4 {
                        display: none;
                    }
                }

            }

        }
    }

}