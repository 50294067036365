.contact-wrap {
    .map-contact {
        >iframe {
            height: 420px;
        }
    }
    .container {
        margin-top: 60px;
        margin-bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        .row {
            width: 100%;

            .col-12 {
                display: flex;
                align-items: center;
                justify-content:center;
                flex-direction: column;

                >p {
                    font-size: 14px;
                    font-weight: 300;
                    margin-bottom: 30px;
                }

                form {
                    width: 55%;

                    .btnsm-wrap {
                        display: flex;
                        align-items: center;
                        justify-content:center;
                    }

                .btn-submit-contact {
                    width: "100%";
                    background: #61b91c;
                    height: auto;
                    padding: 6px 0;
                    font-size: 17px;
                    font-weight: 500;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    width: 170px;
                    margin-top: 20px;
                    color: #fff;
                    transition: all 0.8s;
                    -webkit-transition: all 0.8s;
                }

                .btn-submit-contact:hover {
                    background: rgba(255, 255, 255, 0.5);
                    border: 1px solid rgba(97, 185, 28, 0.5);
                    color: rgba(97, 185, 28, 0.8);
                    transition: all 0.6s ease-in-out;
                }
                }
                .line-t {
                    width: 35%;
                    margin: 30px 0;
                    height: 1px;
                    background: #e8e8e8;
                    border-radius: 10px;
                }

                > ul {
                    li {
                        text-align: center;
                        font-size: 14px;
                        color: #505050;
                        cursor: auto;
                        padding: 4px 0;
                        span {
                            font-weight: 700;
                            color: #292929
                        }
                        font-weight: 300;
                    }
                }

            }
        }
    }



}

@media (max-width: 992px) {
    .contact-wrap {
        .container {
            .row {
                .col-12 {
                    text-align: center;
 
                    form {
                       width: 100%;
                    }

                }
            }
        }
        .map-contact {
            // >iframe {
            //     height: 36vw;
            // }
        }
    }    
    
}
@media (max-width: 480px) {
    .contact-wrap {
        .container {
            .row {
                .col-12 {
                    h1 {
                        font-size:32px;
                    }

                }
            }
        }
        .map-contact {
            // >iframe {
            //     height: 36vw;
            // }
        }
    }    
    
}

@media (min-width: 1440px) {
    
    .contact-wrap {
        .container {
            .row {
                .col-12 {
                    > p {
                        font-size: 0.99999vw;
                    }
                    form {
                        .btn-submit-contact {
                            width: 11.2vw;
                            font-size: 1.1vw;
                        }
                        input, textarea {
                            font-size: 0.9vw;
                            padding: 1.1vw 0.9vw;
                        }
                        .react-international-phone-input, .react-international-phone-country-selector-button {
                            height: 3.9vw !important;
                            padding: 0 0.7vw !important;
                        }
                        .react-international-phone-flag-emoji {
                            width: 1.5vw !important;
                            height: 1.5vw !important;
                        }
                        .react-international-phone-country-selector-button__dropdown-arrow {
                            border-top: 0.3vw solid #777 !important;
                            border-right:0.3vw  solid transparent !important;
                            border-left: 0.3vw solid transparent !important;
                            margin-right: 0.1vw;
                            margin-left: 0.2vw;
                        }
                        .ant-input-textarea-affix-wrapper {
                            height: 12.7vw !important;
                        }
                    }

                    > ul {
                        li {
                            font-size: 0.899999vw;
                            padding: 0.5vw 0;
                        }
                    }
                }
            }
        }
        .map-contact {
            >iframe {
                height: 36vw;
            }
        }
    }           
}

